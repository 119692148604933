import React, { Fragment, useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import EditColorModal from "./EditColorModal";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteColor, getColor } from "../../Redux/Action/color";
import Pagination from "../Pagination/Pagination";
import DeleteModal from "../Modals/DeleteModal";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";

const ColorListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, loading } = useSelector((state) => state.color);
  const [pageSize, setPageSize] = useState(10);
  const [singleData, setSingleData] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [id, setId] = useState();

  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };
  const handleModalOpen = (id) => {
    setId(id);
    setModalOpen(!modalOpen);
  };
  const handleClose = () => setShow(false);
  const handleShow = (color) => {
    setShow(true);
    setSingleData(color);
  };
  const handleDelete = async (id) => {
    dispatch(deleteColor(id));
  };
  useEffect(() => {
    dispatch(getColor({ page, pageSize }));
  }, [page, dispatch, pageSize]);

  return (
    <Fragment>
      <div className="container-fluid colorListing">
        {loading ? (
          <div className="lottie-dash">
            <Loader />
          </div>
        ) : (
          <>
            <div className="row listing">
              <div className="col-sm-12">
                <table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Color</th>
                      <th>Hex Code</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.items?.map((item, index) => (
                      <tr key={item._id}>
                        <td
                          className="assdd"
                          onClick={() =>
                            navigate(`/edit-color-management/${item._id}`)
                          }
                        >
                          {index + 1}
                        </td>
                        <td
                          className="assdd"
                          onClick={() =>
                            navigate(`/edit-color-management/${item._id}`)
                          }
                        >
                          {item.Name}
                        </td>
                        <td
                          className="assdd"
                          onClick={() =>
                            navigate(`/edit-color-management/${item._id}`)
                          }
                        >
                          {item.Status ? "Active" : "In Active"}
                        </td>
                        <td
                          className="assdd"
                          onClick={() =>
                            navigate(`/edit-color-management/${item._id}`)
                          }
                        >
                          <div
                            style={{
                              background: item.ColorCode,
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                            }}
                          ></div>
                        </td>
                        <td
                          className="assdd"
                          onClick={() =>
                            navigate(`/edit-color-management/${item._id}`)
                          }
                        >
                          {item.ColorCode}
                        </td>
                        <td>
                          <DeleteOutlined
                            className="assdd"
                            onClick={() => handleModalOpen(item?._id)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              TotalData={data?.totalCount}
              handlePageChange={handlePageChange}
              currentPage={page}
            />
          </>
        )}
      </div>
      <Modal show={show} onHide={handleClose} className="AddModal">
        <EditColorModal
          show={show}
          handleClose={handleClose}
          data={singleData}
        />
      </Modal>
      <DeleteModal
        handleDelete={handleDelete}
        id={id}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
      />
    </Fragment>
  );
};

export default ColorListing;
