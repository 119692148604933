import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,

  data: [],
};

const shirtTypeReducerSlice = createSlice({
  name: "shirtTypeReducer",
  initialState,
  reducers: {
    getshirtTypeStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getshirtTypeSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    getshirtTypeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  getshirtTypeStart,
  getshirtTypeSuccess,
  getshirtTypeFailure,
  clearError,
} = shirtTypeReducerSlice.actions;

export default shirtTypeReducerSlice.reducer;
