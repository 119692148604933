import React, { useEffect, useRef, useState } from 'react'
import svgData from '../../Data/svg'
import { Button, Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBodyPattern } from '../../Redux/Action/getBodyPattern';
import { getSleevePattern } from '../../Redux/Action/getSleevePattern';
import { getCapPattern } from '../../Redux/Action/getCapPattern';
import { PDFDocument } from "pdf-lib";

const ShirtSvgDisplay = ({
  capColors,
  capMarkingColor,
  sleeveColors,
  sleevesMarkingColor,
  bodyColors,
  bodyMarkingColors,
  bodyId,
  capId,
  sleevesID,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const elementsRef = useRef(null);
  const [downloadFormat, setDownloadFormat] = useState("svg");
  const bodyData = useSelector((state) => state.getBodyPattern.data);
  const sleeveData = useSelector((state) => state.getSleevePattern.data);
  const capData = useSelector((state) => state.getCapPattern.data);
  const [file, setFile] = useState(null);

  const svgRef = useRef(null);
  const { id } = useParams();

  const waterMark = {
    fillRule: "evenodd",
    opacity: 0.15,
  };

  useEffect(() => {
    const ShirtType = id;
    dispatch(getBodyPattern(ShirtType));
    dispatch(getSleevePattern(ShirtType));
    dispatch(getCapPattern(ShirtType));
  }, [dispatch]);

  const handleFormatChange = (value) => {
    setDownloadFormat(value);
  };

  const downloadImage = () => {
    const svg = svgRef.current;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const svgSize = svg.getBoundingClientRect();
    canvas.width = svgSize.width;
    canvas.height = svgSize.height;
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = async () => {
      ctx.drawImage(img, 0, 0);
      let dataURL;
      if (downloadFormat === "svg") {
        dataURL = "data:image/svg+xml;base64," + btoa(svgData);
      } else if (downloadFormat === "png") {
        dataURL = canvas.toDataURL("image/png");
      } else if (downloadFormat === "jpg") {
        dataURL = canvas.toDataURL("image/jpeg");
      } else if (downloadFormat === "pdf") {
        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([svgSize.width, svgSize.height]);
        const pngImage = await pdfDoc.embedPng(canvas.toDataURL("image/png"));
        page.drawImage(pngImage, {
          x: 0,
          y: 0,
          width: svgSize.width,
          height: svgSize.height,
        });
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "image.pdf";
        link.click();
        return;
      }
      const link = document.createElement("a");
      link.download = `image.${downloadFormat}`;
      link.href = dataURL;
      link.click();
    };

    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  };

  const handleClick = () => {
    downloadImage();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  return (
    <div className="imageeeSvg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 650 1010"
        width="100%"
        height="auto"
        ref={svgRef}
        className="shirtsvg"
        style={{ background: "#FFF" }}
      >
        {svgData?.svg.elements?.map((item) => (
          <g data-name="Layer 1">
            {/* Left Sleeve Outline and Design */}
            {item.id == "left-sleeve" && (
              <g id="left-sleeve">
                <path style={sleeveColors} d={item.d} />
              </g>
            )}
            {/* Right Sleeve Outline and Design */}
            {item.id == "right-sleeve" && (
              <g id="right-sleeve">
                <path style={sleeveColors} d={item.d} />
              </g>
            )}
            {item.id == "abc" && (
              <g id="abc">
                <path d={item.d} />
              </g>
            )}

            {/* Body Outline and Design */}
            {item.id == "body" && (
              <g id="body">
                <path style={bodyColors} d={item.d} />
              </g>
            )}
            {item.id == "body-highlight" && (
              <g id="body-highlight">
                <path style={bodyColors} d={item.d} />
              </g>
            )}

            {/* Cap Outline and Design */}
            {
              <g id="cap">
                {item.id == "cap-front" && (
                  <path style={capColors} d={item.d} />
                )}

                {item.id == "cap-bill" && <path style={capColors} d={item.d} />}
                {item.id == "facecut" && (
                  <path className="mainPattern" d={item.d} />
                )}
              </g>
            }
          </g>
        ))}

        {bodyData?.items?.map(
          (item, index) =>
            bodyId === item._id && (
              <svg
                key={index}
                className="bboddy"
                style={{ strokeWidth: "3" }}
                dangerouslySetInnerHTML={{
                  __html: `${item.SvgPath.replace(
                    /^<div>|<\/div>$/g,
                    ""
                  )}<style>.bboddy path { fill: ${
                    bodyMarkingColors.fill
                  }; } .bboddy polygon { fill: ${
                    bodyMarkingColors.fill
                  }; } .bboddy rect { fill: ${
                    bodyMarkingColors.fill
                  }; } .bboddy ellipse { fill: ${
                    bodyMarkingColors.fill
                  }; } .bboddy circle { fill: ${
                    bodyMarkingColors.fill
                  }; } .bboddy polyline { fill: ${
                    bodyMarkingColors.fill
                  }; } .bboddy line { fill: ${
                    bodyMarkingColors.fill
                  }; }</style>`,
                }}
              />
            )
        )}

        {sleeveData?.items?.map(
          (item, index) =>
            sleevesID === item._id && (
              <svg
                key={index}
                className="ssleevvee"
                style={{ strokeWidth: "3" }}
                dangerouslySetInnerHTML={{
                  __html: `${item.SvgPath.replace(
                    /^<div>|<\/div>$/g,
                    ""
                  )}<style>.ssleevvee path { fill: ${
                    sleevesMarkingColor.fill
                  }; } .ssleevvee circle { fill: ${
                    sleevesMarkingColor.fill
                  }; } .ssleevvee polygon{fill: ${
                    sleevesMarkingColor.fill
                  }; } .ssleevvee rect {fill: ${
                    sleevesMarkingColor.fill
                  }; } .ssleevvee ellipse {fill: ${
                    sleevesMarkingColor.fill
                  }; } .ssleevvee polyline {fill: ${
                    sleevesMarkingColor.fill
                  }; } .ssleevvee line {fill: ${
                    sleevesMarkingColor.fill
                  }; }</style>`,
                }}
              />
            )
        )}

        {svgData?.svg.elements?.map((item, i) => (
          <g data-name="Layer 1" key={i}>
            {item.id === "outlinee" && (
              <g id="outlinee">
                {item.pattern.map((itemss, i) => (
                  <path
                    d={itemss.d}
                    style={{ fill: "#e6e7e8", opacity: "0.4" }}
                  />
                ))}
              </g>
            )}
          </g>
        ))}

        {capData?.items?.map(
          (item, index) =>
            capId === item._id && (
              <svg
                key={index}
                className="ccaapp"
                style={{ strokeWidth: "3" }}
                dangerouslySetInnerHTML={{
                  __html: `${item.SvgPath.replace(
                    /^<div>|<\/div>$/g,
                    ""
                  )}<style>.ccaapp path { fill: ${
                    capMarkingColor.fill
                  }; } .ccaapp circle { fill: ${
                    capMarkingColor.fill
                  }; } .ccaapp rect { fill: ${
                    capMarkingColor.fill
                  }; } .ccaapp polygon { fill: ${
                    capMarkingColor.fill
                  }; } .ccaapp ellipse { fill: ${
                    capMarkingColor.fill
                  }; } .ccaapp line { fill: ${
                    capMarkingColor.fill
                  }; } .ccaapp polyline { fill: ${
                    capMarkingColor.fill
                  }; }</style>`,
                }}
              />
            )
        )}
        <g id="eeellleBody">
          {svgData?.svg.elements?.map((item) => (
            <g>
              {item.id == "watermark" && (
                <g id="watermark">
                  <path style={waterMark} d={item.d} id="eeellleBody" />
                </g>
              )}

              {item.id == "watermark-1" && (
                <g id="watermark-1">
                  <path style={waterMark} d={item.d} id="eeellleBody" />
                </g>
              )}

              {item.id == "watermark-2" && (
                <g id="watermark-2">
                  <path style={waterMark} d={item.d} id="eeellleBody" />
                </g>
              )}

              {item.id == "watermark-3" && (
                <g id="watermark-3">
                  <path style={waterMark} d={item.d} id="eeellleBody" />
                </g>
              )}

              {item.id == "watermark-4" && (
                <g id="watermark-4">
                  <path style={waterMark} d={item.d} id="eeellleBody" />
                </g>
              )}

              {item.id == "watermark-5" && (
                <g id="watermark-5">
                  <path style={waterMark} d={item.d} id="eeellleBody" />
                </g>
              )}

              {item.id == "watermark-6" && (
                <g id="watermark-6">
                  <path style={waterMark} d={item.d} id="eeellleBody" />
                </g>
              )}

              {item.id == "watermark-7" && (
                <g id="watermark-7">
                  <path style={waterMark} d={item.d} id="eeellleBody" />
                </g>
              )}

              {item.id == "watermark-8" && (
                <g id="watermark-8">
                  <path style={waterMark} d={item.d} id="eeellleBody" />
                </g>
              )}
            </g>
          ))}
        </g>
      </svg>
      <Select
        value={downloadFormat}
        onChange={handleFormatChange}
        style={{ margin: "10px 0" }}
      >
        <Select.Option value="svg">Download as SVG</Select.Option>
        <Select.Option value="png">Download as PNG</Select.Option>
        <Select.Option value="jpg">Download as JPEG/JPG</Select.Option>
        <Select.Option value="pdf">Download as PDF</Select.Option>
      </Select>
      <div className="button">
        <Button className="downLOad" onClick={downloadImage}>
          Download
        </Button>
      </div>
    </div>
  );
};

export default ShirtSvgDisplay


