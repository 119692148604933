import React, { Fragment } from 'react'
import {Button, Tabs} from 'antd'
import restartImage from '../../../Assets/restart.png'
import { useSelector } from 'react-redux';
import ShirtTwoSleeveMarkingSlider from '../ShirtTwoSlider/ShirtTwoSleeveMarkingSlider';
import ListColor from '../../ColorCompTab/ListColor';

const { TabPane } = Tabs;

const SleeveTabTwo = ({handlesleevesColor, handleSleevesMarkingColor, handleSleeveId, sleeveColors, sleevesMarkingColor}) => {
    const { data } = useSelector((state) => state.color);
    
    return (
        <Fragment>
            <div className="row boddyTab">
                <div className="col-sm-12">
                    <Tabs defaultActiveKey='1'>
                        <TabPane tab="Sleeve Colour"  key="1">
                            <ListColor handleColor={handlesleevesColor} colorsss={sleeveColors}/>
                        </TabPane>
                        <TabPane tab="Sleeve Marking" key="2" >
                            <ShirtTwoSleeveMarkingSlider handleSleeveId={handleSleeveId}/>
                        </TabPane>
                        <TabPane tab="Sleeve Marking Colour" key="3">
                            <ListColor handleColor={handleSleevesMarkingColor} colorsss={sleevesMarkingColor}/>
                        </TabPane>                
                    </Tabs>        
                </div>
            </div>
        </Fragment>
    )
}

export default SleeveTabTwo