import { Button, Form, Input, Select, Upload, message } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { postPattern } from "../../Redux/Action/pattern";
import { useParams } from "react-router-dom";
import { clearError } from "../../Redux/Reducer/patternReducer";
import { Modal } from "react-bootstrap";

const AddModal = ({ patternType, designName, handleClose, place }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { postSuccess, postError } = useSelector((state) => state.pattern);

  const onFinish = (value) => {
    const formData = new FormData();
    formData.append("Name", value.Name);
    formData.append("Status", value.Status);
    formData.append("PatternPlace", value.PatternPlace);
    formData.append("ShirtType", id);

    const file = value.file?.fileList[0]?.originFileObj;
    if (file) {
      formData.append("image", file);
    }

    dispatch(postPattern(formData));
  };

  const onFinishFailed = (errorInfo) => {
    // Extracting and displaying the validation error messages
    const errorMessages = errorInfo.errorFields.map((errorField) =>
      errorField.errors.join(", ")
    );
    message.error(`Validation failed: ${errorMessages.join(" ")}`);
  };

  useEffect(() => {
    if (postError) {
      message.error(postError);
      dispatch(clearError());
    }
    if (postSuccess) {
      message.success(postSuccess);
      dispatch(clearError());
      handleClose();
    }
  }, [postSuccess, postError]);

  return (
    <Fragment>
      <div className="container-fluid modaal">
        <div className="row">
          <div className="col-sm-12 forrrms">
            <h2>
              Add {patternType} Pattern For {place}
            </h2>
          </div>
        </div>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          initialValues={{
            PatternPlace: place, // Setting the initial value for PatternPlace
          }}
        >
          <div className="row">
            <div className="col-sm-8">
              <div className="row noPad">
                <div className="col-sm-12">
                  <h3>Upload Details</h3>
                </div>
                <div className="col-sm-12 inot">
                  <Form.Item
                    name="Name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter pattern name!",
                      },
                    ]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                </div>

                <div className="col-sm-12">
                  <Form.Item
                    name="Status"
                    label="Status"
                    rules={[
                      {
                        required: true,
                        message: "Please select pattern status!",
                      },
                    ]}
                  >
                    <Select placeholder="Active/Inactive">
                      <Select.Option value={true}>Active</Select.Option>
                      <Select.Option value={false}>Inactive</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm-12">
                  <Form.Item
                    name="PatternPlace"
                    label="Pattern Place"
                    rules={[
                      {
                        required: true,
                        message: "Please select pattern place!",
                      },
                    ]}
                  >
                    <Select placeholder="Pattern Place">
                      <Select.Option value="body">Body</Select.Option>
                      <Select.Option value="sleeve">Sleeve</Select.Option>
                      <Select.Option value="cap">Cap</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="row noPad">
                <div className="col-sm-12">
                  <h3>Upload {patternType} Pattern</h3>
                </div>
                <div className="col-sm-12">
                  <Form.Item
                    name="file"
                    rules={[
                      {
                        required: true,
                        message: "Please upload pattern!",
                      },
                    ]}
                  >
                    <Upload
                      action="/upload.do"
                      beforeUpload={() => false}
                      accept="image/svg+xml"
                      name="logo"
                      maxCount={1}
                      listType="picture-card"
                    >
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className="col-sm-12 submit">
              <div className="row noPad"></div>
            </div>
            <div className="col-sm-12 forrrms">
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default AddModal;