import React, { Fragment } from 'react'
import { Button, Tabs } from 'antd'
import SleeveTab from './TabsComponent/SleeveTab'
import CapTab from './TabsComponent/CapTab'
import menuubody from '../../Assets/body.png';
import menuuSleeve from "../../Assets/sleeve.png";
import menuuCap from "../../Assets/cap.png";
import BodyTab from './TabsComponent/BodyTab'

const ShirtTabs = ({handleCapColor, handleCapMarkingColor, handleCapId, capColors, capMarkingColor, handlesleevesColor, handleSleevesMarkingColor, handleSleeveId,  sleeveColors, sleevesMarkingColor, handleBodyColor, handleBodyMarkingColor, handleBodyId, bodyColors, bodyMarkingColors}) => {
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <Tabs tabPosition="left" >
                            <Tabs.TabPane
                                key="1"
                                tab={
                                    <Button>
                                        <img src={menuubody} alt="asd" />
                                        Body
                                    </Button>
                                }
                            >
                                <BodyTab
                                    handleBodyColor={handleBodyColor}
                                    handleBodyMarkingColor={handleBodyMarkingColor}
                                    handleBodyId={handleBodyId}
                                    bodyColors={bodyColors}
                                    bodyMarkingColors={bodyMarkingColors}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                key="2"
                                tab={
                                    <Button>
                                        <img src={menuuSleeve} alt="asd" />
                                        Sleeves
                                    </Button>
                                }
                            >
                                <SleeveTab
                                    handlesleevesColor={handlesleevesColor}
                                    handleSleevesMarkingColor={handleSleevesMarkingColor}
                                    handleSleeveId={handleSleeveId}
                                    sleeveColors={sleeveColors}
                                    sleevesMarkingColor={sleevesMarkingColor}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                key="3"
                                tab={
                                    <Button>
                                        <img src={menuuCap} alt="asd" />
                                        Cap
                                    </Button>
                                }
                            >
                                <CapTab
                                    handleCapColor={handleCapColor}
                                    handleCapMarkingColor={handleCapMarkingColor}
                                    handleCapId={handleCapId}
                                    capColors={capColors}
                                    capMarkingColor={capMarkingColor}
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ShirtTabs