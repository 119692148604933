import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  editError: null,
  editSuccess: null,
  postError: null,
  postSuccess: null,
  singleData: {},
  data: [],
};

const colorReducerSlice = createSlice({
  name: "colorReducer",
  initialState,
  reducers: {
    getColorReducerStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getColorReducerSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    getColorReducerFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    postColorReducerStart(state, payload) {
      state.loading = true;
      state.postError = null;
    },

    postColorReducerSuccess(state, action) {
      state.loading = false;
      state.postSuccess = action.payload.message;
    },
    postColorReducerFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
    },
    editColorReducerStart(state, payload) {
      state.loading = true;
      state.editError = null;
    },

    editColorReducerSuccess(state, action) {
      state.loading = false;
      state.editSuccess = action.payload.message;
    },
    editColorReducerFailure(state, action) {
      state.loading = false;
      state.editError = action.payload;
    },
    deleteColorReducerStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    deleteColorReducerSuccess(state, action) {
      state.loading = false;
      state.success = action.payload.message;
    },
    deleteColorReducerFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getSingleColorReducerStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getSingleColorReducerSuccess(state, action) {
      state.loading = false;
      state.singleData = action.payload;
    },
    getSingleColorReducerFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
      state.success = null;
      state.editError = null;
      state.editSuccess = null;
      state.postError = null;
      state.postSuccess = null;
      state.singleData = {};
    },
  },
});

export const {
  getColorReducerStart,
  getColorReducerSuccess,
  getColorReducerFailure,
  postColorReducerStart,
  postColorReducerSuccess,
  postColorReducerFailure,
  editColorReducerStart,
  editColorReducerSuccess,
  editColorReducerFailure,
  deleteColorReducerStart,
  deleteColorReducerSuccess,
  deleteColorReducerFailure,
  getSingleColorReducerStart,
  getSingleColorReducerSuccess,
  getSingleColorReducerFailure,
  clearError,
} = colorReducerSlice.actions;

export default colorReducerSlice.reducer;
