import React, { Fragment } from 'react'
import {Tabs} from 'antd'
import SleeveMarkingSlider from '../Slider/SleeveMarkingSlider';
import { useSelector } from "react-redux";
import ListColor from '../../ColorCompTab/ListColor';

const { TabPane } = Tabs;

const SleeveTab = ({
  handlesleevesColor,
  handleSleevesMarkingColor,
  handleSleeveId,
  sleeveColors,
  sleevesMarkingColor,
}) => {
  return (
    <Fragment>
      <div className="row boddyTab">
        <div className="col-sm-12">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Sleeve Colour" key="1">
              <ListColor handleColor={handlesleevesColor} colorsss={sleeveColors}/>
            </TabPane>
            <TabPane tab="Sleeve Marking" key="2">
              <SleeveMarkingSlider handleSleeveId={handleSleeveId} />
            </TabPane>
            <TabPane tab="Sleeve Marking Colour" key="3">
              <ListColor handleColor={handleSleevesMarkingColor} colorsss={sleevesMarkingColor}/>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Fragment>
  );
};

export default SleeveTab