import React, { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { getColor } from '../../Redux/Action/color';

const HeaderDesignCreationPurpleJacket = () => {
    const dispatch = useDispatch();
    useEffect(() => {
      const status = "true";
      const pageSize = 100;
      dispatch(getColor({status, pageSize}));
    }, [dispatch]);
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 dashBoard">
                        <h2>Design Creation</h2>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default HeaderDesignCreationPurpleJacket
