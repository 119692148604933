import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getDashboardStart,
  getdashboardFailure,
  getdashboardSuccess,
} from "../Reducer/dashboardReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const dashboard = createAsyncThunk(
  "dashboardReducer",
  async ({ startDate, endDate }, thunkAPI) => {
    try {
      thunkAPI.dispatch(getDashboardStart());
      const token = Cookies.get("token");

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const queryParams = {
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}DashboardApi?`,
        {
          params: queryParams,
          headers: config.headers,
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(getdashboardSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getdashboardFailure(error.response.data.message));

      throw error;
    }
  }
);
