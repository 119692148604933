import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getShirtType } from "../../Redux/Action/getShirtType";
import Loader from "../Loader/Loader";

const Shirts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.shirtType);

  useEffect(() => {
    dispatch(getShirtType());
  }, [dispatch]);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row shirtgs">
          {/* <Swiper
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            spaceBetween={20}
            slidesPerView={4}
          >
            {data?.items?.map((item, i) => (
              <SwiperSlide>
                <div className="col-sm-12" key={i}>
                  <img
                    src={item.Image}
                    alt={item.Name}
                    onClick={() => navigate(`/design-management/${item._id}`)}
                  />
                  <h1>{item.Name}</h1>
                </div>
              </SwiperSlide>
            ))}
          </Swiper> */}
          {loading ? (
            <div className="lottie-dash">
              <Loader />
            </div>
          ) : (
            data?.items?.map((item, i) => (
              // <SwiperSlide>
              <div className="col-sm-3" key={i}>
                <img
                  src={item.Image}
                  alt={item.Name}
                  onClick={() => navigate(`/design-management/${item._id}`)}
                />
                <h1>{item.Name}</h1>
              </div>
              // </SwiperSlide>
            ))
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Shirts;
