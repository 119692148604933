import { Button, Form, Input, message } from 'antd';
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom';

const ResetCodes = () => {
    const navigate = useNavigate();
    const onFinish = (values) => {
        message.success("Success")
    };
    const onFinishFailed = (errorInfo) => {
    message.error("Failed");
    };
    return (
        <Fragment>
            <div className="container-fluid">
                <Form
                    name="login"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='on'
                    layout='vertical'
                >
                    <div className="row">
                        <div className="col-sm-12">
                            <Form.Item
                                name='code'
                                rules={[
                                    { 
                                        required: true, 
                                        message: 'Please enter code'
                                    }
                                ]}
                            >
                                <Input placeholder='Code' />
                            </Form.Item>
                        </div>

                        <div className="col-sm-12 submit">
                            <p>Do you want to login ? <b onClick={() => navigate('/')}>Login</b></p>
                            <Button htmlType='submit' type='primary'>Next</Button>                           
                        </div>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default ResetCodes