import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getShirts } from "../../Data";
import { Tabs } from "antd";
import Chesst from "./Chesst";

const TabsShirt = ({ showEdit, setShowEdit }) => {
  const { id } = useParams();

  const [place, setPlace] = useState("body");
  const [ShirtType, setShirtType] = useState();

  const handleTabChange = (key) => {
    switch (key) {
      case "1":
        setPlace("body");

        break;
      case "2":
        setPlace("sleeve");

        break;
      case "3":
        setPlace("cap");

        break;
      default:
        break;
    }
  };
  useEffect(() => {
    setShirtType(id);
  }, [ShirtType]);
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 dmTabs">
            <Tabs
              defaultActiveKey="1"
              className="tabsShort"
              onChange={handleTabChange}
            >
              <Tabs.TabPane tab="BODY" key="1">
                <Chesst
                  place={place}
                  showEdit={showEdit}
                  setShowEdit={setShowEdit}
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="2" tab="SLEEVES">
                <Chesst
                  place={place}
                  showEdit={showEdit}
                  setShowEdit={setShowEdit}
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="3" tab="CAP">
                <Chesst
                  place={place}
                  showEdit={showEdit}
                  setShowEdit={setShowEdit}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TabsShirt;
