const thirdSgvData = {
  svg: {
    elements: [
      {
        id: "left-sleeve",
        d: "M27.86,694.36C28,696.24,30,738.83,46.14,767l81.64-25.48c.39-20.07,4.13-132.2,39.8-180.45L151.49,447.5c-10,4.54-35.08,17.82-59.8,46.06C62.37,527.05,27.5,589.07,27.86,694.36Z",
        type: "path",
      },
      {
        id: "left-sleeve-border",
        d: "M42.58,776.49l-1.64-2.61c-19-30.37-21-77.3-21.07-79.28v-.14c-.17-47.67,6.68-90.78,20.38-128.15,11-30,26.39-56.38,45.77-78.41,33.12-37.65,66-49.38,67.41-49.86l4.59-1.59,18,126.81-1,1.34c-37.77,48.95-39.19,178.6-39.2,179.9l0,2.92Z",
        type: "path",
      },
      {
        id: "right-sleeve-border",
        d: "M593.67,770.68a22.57,22.57,0,0,1-3.56-.28c-13.83-2.31-72.47-23.29-75-24.18l-3.65-1.31,1.19-3.68c0-.16,5.19-16.88,1.37-44.87-3.53-25.9-15.68-67-53.46-115.82l-1.07-1.38,24.35-146.39,4.56,1.74c1.44.54,35.57,13.79,70.34,51.9a256.81,256.81,0,0,1,48.48,78.06c14.72,36.74,22.41,78.59,22.88,124.38v.3c-.14,2.23-3.55,54.7-4,59.76-.52,5.21-7.92,11.24-10.18,13C612.22,764.7,603.26,770.68,593.67,770.68Z",
        type: "path",
      },
      {
        id: "right-sleeve",
        d: "M521.27,739.9c13,4.6,58.85,20.72,70.16,22.6s25.66-11.07,26.7-14.48c.51-5.34,3.8-56.08,4-59.24-1.07-101-38.09-162.68-69-196.66-26.29-28.91-52.71-43.24-63-48.13L468,577c38.24,49.87,50.52,92.1,54.08,118.84C525,717.9,522.72,733.21,521.27,739.9Z",
        type: "path",
      },
      {
        id: "body-border",
        d: "M423.22,830H201.86L152.31,441l1.11-1.34c6.91-8.29,52.77-18.3,67.52-21.34,24.49-12.08,38.83-43,39-43.32l.9-2,2.13-.33c13.57-2.08,41.08-2.8,73.59-1.92,11.81.32,50.7,1.6,55.24,4.63,2.24,1.49,4.24,5,8.19,12.23C405,397,413.52,412.46,421,414.75c13.46,4.11,68,19.91,68.54,20.07l3.45,1Z",
        type: "path",
      },
      {
        id: "body",
        d: "M208.9,822H416.51L483.89,441.5c-12.28-3.56-53.67-15.58-65.19-19.1-10.63-3.25-19.38-19.21-25.76-30.87-2-3.6-4.4-8-5.52-9.3-8.16-2.26-89.18-6.07-121.2-1.87-3.8,7.55-18.44,33.95-42.24,45.42l-.45.22-.48.1c-25.82,5.29-55.43,13.25-62.32,17.76Z",
        type: "path",
      },
      {
        id: "cap-border",
        d: "M437.33,331.65l-1.21,0-258-3.6.45-4.35c5.92-57.29,27.58-90.65,44.71-108.55,18-18.78,34.61-24.93,36.65-25.64,39.89-17,81.15-13,122.64,11.88,33.7,20.24,60,50,74.09,65.94,3.89,4.41,7,7.88,9.1,10a17.7,17.7,0,0,1,5.62,14.82c-1.83,18.3-29.84,36.85-33,38.9Z",
        type: "path",
      },
      {
        id: "cap",
        d: "M187,320.15l248,3.46c8.38-5.61,27.24-20.54,28.41-32.32a9.58,9.58,0,0,0-3.24-8.29c-2.35-2.28-5.51-5.86-9.51-10.39-30-33.89-100.1-113.26-187.73-75.7l-.33.12c-.15.05-16.48,5.63-33.9,24C213,237.56,193.26,268.07,187,320.15Z",
        type: "path",
      },
      {
        id: "shirt-inside-one-border",
        d: "M330.19,385.75c-15.15,0-28.88,1.5-39.81,2.43C268.11,390.07,260.29,419,260.29,419H389.51c-3.94-2.33-5.29-9.19-6.9-15.29-1.86-7.05-6.84-12.32-13-13.75a174,174,0,0,0-39.44-4.17Z",
        type: "path",
      },
      {
        id: "shirt-inside-one",
        d: "M330.2,393.75a165.11,165.11,0,0,1,37.62,4c3.2.75,6,3.89,7.06,8,.17.64.34,1.3.51,2,.27,1.07.55,2.17.86,3.28H272.34c3.65-6.57,9.73-14,18.72-14.81q2.7-.22,5.62-.5c9.93-.89,21.2-1.9,33.52-1.9h0",
        type: "path",
      },
      {
        id: "shirt-inside-two-border",
        d: "422.43 829.01 415.93 825.24 460.25 578.04 467.47 576.45 486.72 603.94 487.42 606.66 467.73 789.71 466.32 792.36 422.43 829.01",
        type: "polygon",
      },
      {
        id: "shirt-inside-two",
        d: "466.43 588.92 425.74 815.82 459.95 787.25 479.31 607.3 466.43 588.92",
        type: "polygon",
      },
      {
        id: "abc",
        d: "M649.9.1V1009.9H.1V.1H649.9M650,0H0V1010H650V0Z",
        type: "path",
      },
      {
        id: "watermark",
        d: "M134,909.8c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18L131,885l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.2,10.9c-1.5,4.7-3,9.3-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.2,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.5l-3.5-3.6c1.1-2.1,2.1-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7   l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.3   l7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.8,3.6,1.3,5.4c7.2-3,31.3-11.8,33.6-16.3C144.4,935.8,138.1,911,134,909.8z    M96.4,923.8l1,0.4l-3.7,1.6l-1.1-0.6l-1.1-3.8L96.4,923.8z M70.4,916.1l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9   L70.4,916.1z M116.3,940.9l-2-7.6l-9.3,4.1c0.5,2.2,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8l2.5-0.5   c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.6c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.4,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6   c0-0.1-0.1-0.1-0.1-0.2l-13.2,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.2-9.7l3.3,12.4L116.3,940.9z",
        type: "path",
      },
      {
        id: "watermark-1",
        d: "M293.2,750.5c-5-1.4-17,5.7-23.2,8.5c-0.5-2.3-1.1-4.6-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.8   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.4,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.4,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10c1.4,2.4,2.8,4.8,4.2,7.1l4.8-6.4l-3.5-3.6c1.1-2.1,2.2-4.2,3.2-6.3   c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.3-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.7h0.6   c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4   c7.2-3,31.2-11.8,33.6-16.3C303.7,776.5,297.4,751.7,293.2,750.5z M255.6,764.5l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.8L255.6,764.5z    M229.7,756.8l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L229.7,756.8z M275.6,781.6c-0.6-2.5-1.3-5-2-7.6l-9.4,4.1   c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9c0.9-1.9,1.8-3.8,2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1   c-0.4,1.2-0.8,2.3-1.2,3.5c0.9-0.8,1.6-2,2.6-2.2c0.9,0.3,0.4,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2   l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.3l22.1-9.7c1.1,4.1,2.2,8.3,3.3,12.4L275.6,781.6z",
        type: "path",
      },
      {
        id: "watermark-2",
        d: "M452.5,591.3c-5-1.4-17,5.7-23.2,8.5c-0.6-2.3-1.1-4.6-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4   l-4.4-0.8l-1.4-0.7c1.3-1.3,2.7-2.6,4-3.9l0.5-0.2l-6.8-1.9l41-18c-0.8-3.5-1.6-6.9-2.4-10.4l-52.5,23l-12.4,1.6l-6,1.8l-9.2-2.5   c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6   c2.9,1.4,7.4,7.7,9.1,10.9c-1.5,4.7-3,9.3-4.5,13.9c-2.8,3.8-10.2,5.7-13.4,9.1c-1.2,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.5l-3.5-3.6   c1.1-2.1,2.1-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5c0.3,1,0.6,2.1,0.9,3.2c-13.1,5.4-25.6,9.5-36.3,8.3   c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4l7.1-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1   c0.4,1.8,0.8,3.6,1.3,5.4c7.2-3,31.2-11.8,33.6-16.3C463,617.2,456.7,592.4,452.5,591.3z M414.9,605.3l1,0.4l-3.6,1.6l-1.1-0.6   l-1.1-3.8L414.9,605.3z M388.9,597.6l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1l0.8,2.9L388.9,597.6z M434.8,622.3l-2-7.6l-9.4,4.1   c0.5,2.2,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.6   c0.9-0.8,1.6-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2   c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4L434.8,622.3z",
        type: "path",
      },
      {
        id: "watermark-3",
        d: "M611.7,432c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7l4-3.9l0.5-0.2   l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2   c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9c-2.8,3.8-10.2,5.7-13.4,9.1   c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1c1.6-2.1,3.2-4.3,4.8-6.4l-3.5-3.6c1.1-2.1,2.2-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4   l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.5c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4c7.2-3,31.2-11.8,33.6-16.4C622.2,458,615.9,433.2,611.7,432z    M574.1,446l1,0.4l-3.7,1.6l-1.1-0.6l-1.1-3.9L574.1,446z M548.2,438.3l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9   L548.2,438.3z M594.1,463.1c-0.7-2.5-1.3-5-2-7.6l-9.3,4.1c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8   l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1c-0.4,1.2-0.8,2.3-1.2,3.5c0.9-0.9,1.7-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3   c8.3-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.1-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L594.1,463.1z",
        type: "path",
      },
      {
        id: "watermark-4",
        d: "M586.7,630.2c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7l4-3.9l0.5-0.2   l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23L519,630l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2   c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9c-2.8,3.8-10.2,5.7-13.4,9.1   c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1c1.6-2.2,3.2-4.3,4.8-6.4l-3.5-3.6c1.1-2.1,2.2-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4   l16.9-8.5l0.8,3.2c-13.1,5.3-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.5c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4c7.2-3,31.2-11.8,33.6-16.4C597.2,656.2,590.9,631.4,586.7,630.2z    M549.1,644.2l1,0.4l-3.7,1.6l-1.1-0.6l-1.1-3.8L549.1,644.2z M523.2,636.6l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1   c0.2,1,0.5,2,0.7,2.9L523.2,636.6z M569.1,661.3c-0.7-2.5-1.3-5-2-7.6l-9.3,4.1c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9   l-9.6-4l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1c-0.4,1.2-0.8,2.3-1.2,3.5c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3   c8.3-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L569.1,661.3z",
        type: "path",
      },
      {
        id: "watermark-5",
        d: "M116.4,525.3c-5-1.4-17,5.7-23.2,8.5c-0.6-2.3-1.1-4.6-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.3,0.4   l-4.4-0.8l-1.4-0.7l4-3.9l0.4-0.2l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2   l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1c1.6-2.1,3.2-4.3,4.8-6.4l-3.5-3.6l3.2-6.3c1.3-1.9,9.3-1.5,12-1.7   l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.3-25.6,9.5-36.3,8.2c-1.6,4-2,8.3-4.2,12.2l5.7,5.7h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   l7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.9,3.6,1.3,5.4c7.2-3,31.2-11.8,33.6-16.3   C126.9,551.3,120.6,526.5,116.4,525.3z M78.8,539.3l1,0.4l-3.7,1.6l-1.1-0.6c-0.4-1.3-0.8-2.6-1.2-3.8L78.8,539.3z M52.9,531.7   l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L52.9,531.7z M98.8,556.5l-2-7.6l-9.3,4.1c0.5,2.1,1,4.3,1.4,6.4   l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9c0.9-1.9,1.8-3.9,2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.5   c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.4,0.2,0.8,1.3c8.3-3.6,14.4-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2   c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2c-0.2-0.1-0.3-0.2-0.4-0.3l22.2-9.7l3.3,12.4L98.8,556.5z",
        type: "path",
      },
      {
        id: "watermark-6",
        d: "M275.7,366.1c-5-1.4-17,5.7-23.2,8.5l-1.6-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.9-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.4,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.4,4.6   c1.2-0.9,2.2-1.9,3.7-2.1c-0.6-2.4-1.2-4.8-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,10.9l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.5l-3.5-3.6l3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5   l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2.1,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4c7.2-3,31.2-11.8,33.6-16.3C286.2,392,279.9,367.3,275.7,366.1z    M238.1,380.1l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.9L238.1,380.1z M212.2,372.4l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1   c0.2,1,0.5,2,0.7,2.9L212.2,372.4z M258.1,397.2l-2-7.6l-9.4,4.1c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9   l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.5c0.9-0.8,1.6-2,2.6-2.2c0.9,0.3,0.5,0.2,0.8,1.3   c8.4-3.6,14.4-3.1,22.8-5.6c0-0.1-0.1-0.1-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L258.1,397.2z",
        type: "path",
      },
      {
        id: "watermark-7",
        d: "M435,206.8c-5-1.4-17,5.7-23.2,8.5c-0.5-2.3-1.1-4.6-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18L432,182l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.4,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,10.9l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10c1.4,2.4,2.8,4.8,4.2,7.1l4.8-6.4c-1.2-1.2-2.3-2.4-3.5-3.6l3.2-6.3   c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.5   c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4l7.1-9.1c13,0.6,17.5,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.9,3.6,1.3,5.4   c7.2-3,31.2-11.8,33.6-16.4C445.5,232.8,439.1,208,435,206.8z M397.4,220.8l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.9L397.4,220.8z    M371.5,213.1l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L371.5,213.1z M417.4,237.9c-0.7-2.5-1.3-5-2-7.6l-9.4,4.1   c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9c0.9-1.9,1.8-3.9,2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1   l-1.2,3.5c0.9-0.9,1.7-2,2.6-2.2c0.9,0.3,0.5,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.1-0.1-0.2l-13.3,2l-0.1-0.2   c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2c-0.1-0.1-0.3-0.2-0.4-0.4l22.1-9.7c1.1,4.1,2.2,8.3,3.3,12.4L417.4,237.9z",
        type: "path",
      },
      {
        id: "watermark-8",
        d: "M594.2,47.6c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.8-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.2,11c-1.5,4.7-3,9.3-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.4l-3.5-3.7c1.1-2.1,2.1-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7   l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.6-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.8,3.6,1.3,5.4c7.2-3,31.2-11.8,33.6-16.3   C604.7,73.5,598.4,48.7,594.2,47.6z M556.6,61.6l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.8L556.6,61.6z M530.7,53.9l-8.1-1.1   c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L530.7,53.9z M576.6,78.7l-2-7.6l-9.4,4.1c0.5,2.2,1,4.3,1.5,6.4l-18.8,6.1   l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.5c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3   c8.3-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L576.6,78.7z",
        type: "path",
      },
    ],
  },
};
  export default thirdSgvData;
  