import { Button, ColorPicker, Form, Input, Select, message } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editColor, getSingleColor } from "../../Redux/Action/color";
import { clearError } from "../../Redux/Reducer/colorReducer";
import { useNavigate, useParams } from "react-router-dom";

const EditColorModal = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { id } = useParams();
  const dispatch = useDispatch();
  const { editSuccess, editError, singleData } = useSelector(
    (state) => state.color
  );

  const [colorCode, setColorCode] = useState(null);

  const handleColorChange = (color) => {
    const metaColor = color.metaColor;
    const hexColor = `#${metaColor.toHex()}`;
    setColorCode(hexColor);
    form.setFieldValue("ColorCode", hexColor);
  };

  const onFinish = async (value) => {
    const values = {
      Name: value.Name,
      ColorCode: colorCode,
      Status: value.Status,
    };
    await dispatch(editColor({ id, values }));
  };

  const onFinishFailed = (errorInfo) => {
    message.error(errorInfo);
  };

  useEffect(() => {
    if (singleData) {
      setColorCode(singleData?.ColorCode);
      form.setFieldsValue({
        Name: singleData?.Name,
        ColorCode: singleData?.ColorCode,
        Status: singleData?.Status,
      });
    }
  }, [singleData]);

  useEffect(() => {
    if (editError) {
      message.error(editError);
      dispatch(clearError());
    }
    if (editSuccess) {
      message.success(editSuccess);
      navigate("/color-management");
      dispatch(clearError());
    }
  }, [editError, editSuccess]);

  useEffect(() => {
    dispatch(getSingleColor(id));
  }, [dispatch, id]);

  return (
    <Fragment>
      <div className="container-fluid modaal">
        <div className="row">
          <div className="col-sm-12 forrrms close">
            <h2>Edit Color</h2>
          </div>
        </div>
        {singleData && (
          <Form
            form={form}
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <div className="row">
              <div className="col-sm-12 forrrms inot">
                <Form.Item
                  name="Name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Color name!",
                    },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </div>
              <div className="col-sm-12 forrrms">
                <Form.Item
                  name="ColorCode"
                  label="Background Color"
                  className="colors"
                >
                  <ColorPicker
                    showText
                    trigger="hover"
                    color={colorCode}
                    onChange={handleColorChange}
                  />
                </Form.Item>
              </div>
              <div className="col-sm-12 forrrms">
                <Form.Item
                  name="Status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Please select pattern status!",
                    },
                  ]}
                >
                  <Select placeholder="Active/Inactive">
                    <Select.Option value={true}>Active</Select.Option>
                    <Select.Option value={false}>Inactive</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="col-sm-12 forrrms">
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </div>
          </Form>
        )}
      </div>
    </Fragment>
  );
};

export default EditColorModal;
