const fourthSvgData = {
  svg: {
    elements: [
      {
        id: "left-sleeve-border",
        d: "M210.1,768.6c-6.1,0-17.7-1.4-25.8-7.8c-5.1-4.1-10.2-3.6-15.7-3.2c-4.6,0.4-9.3,0.8-14.5-1.3  c-11.2-4.5-55.8-46.4-58.5-48.9c-25.2-11.6-41.3-34.2-47.9-67.1c-3.9-20.4-4.2-41.4-0.8-61.9c1.7-9,10.7-19.2,21.1-31.1  c13-14.8,29.2-33.2,37.7-56.5c15-41.7,54.5-74.2,54.9-74.5l0.1-0.1c1.4-0.8,3.1-1,4.7-0.8h0.1l0.1,0.1c15.2,13.7,23,130,23.3,142.1  c0.3,9.9-17.9,36.8-28.7,52.9c-4.1,6.1-8,11.8-8.1,12.7c1,3.4,44,58,52.6,59.4c9.9,1.6,34.5,37.3,37.1,41.9c0.8,1.4,0,4.4-2.6,9.3  c-6.2,12-22.9,34.3-26.6,34.7l0,0C212,768.5,211.1,768.6,210.1,768.6z",
        type: "path",
      },
      {
        id: "left-sleeve-color",
        d: "M173.8,756.3c4.1-0.2,8.1,1.1,11.2,3.7c9.2,7.3,23.1,7.9,27.7,7.5l0,0c2.8-0.3,19.2-21.4,25.8-34.1  c3.2-6.1,2.9-7.8,2.6-8.3c-3.5-6.2-27.4-40-36.4-41.5c-5.2-0.8-19.3-16.7-30.2-29.8c-11.7-14.1-23-29.1-23.2-30.5  c-0.1-0.8,1.5-3.4,8.2-13.3c10.8-16,28.8-42.8,28.5-52.3c-0.5-18.3-8.8-128.1-22.9-141.3c-1.3-0.1-2.7,0.1-3.9,0.7  c-1.6,1.3-39.9,33.2-54.6,74c-8.5,23.5-24.8,42-37.8,56.9c-10.4,11.7-19.3,21.9-20.9,30.6c-0.2,1-17.6,97.8,48.2,127.9l0.1,0.1  c0.5,0.4,47,44.3,58.2,48.8c5,2,9.6,1.6,14.1,1.2C170.3,756.5,172.1,756.3,173.8,756.3z",
        type: "path",
      },
      {
        id: "right-sleeve-border",
        d: "M511.3,815.8c-1.5,0-5-2.2-11.4-6.4c-8.9-5.8-21.2-13.8-31.2-16.4l-0.4-0.1v-0.4c0-0.5,0.5-12.2,8.4-18.8  c0.1-4.2,1.7-52.5,7-56.6c4.9-3.9,18.2-27,19.5-29.2c-0.8-2.8-10-34.2-22.2-39.5l-0.4-0.2l0.1-0.4c0.1-0.8,13.5-85.5,12.9-96.5  c-0.5-9.1,10.3-14.4,17.4-17.9c1.6-0.7,3.1-1.5,4.6-2.4c4.2-2.7,17.6-48.6,22.3-66.1l0.2-0.6l0.5,0.3c1,0.5,23.8,14.1,61.3,178.6  c0.2,0.5,20,56.7-4.6,95.9l-0.1,0.1c-2.4,2.4-58.6,57.8-73.4,62C520.9,803.5,515.1,815.8,511.3,815.8z",
        type: "path",
      },
      {
        id: "right-sleeve-color",
        d: "M469.4,792.2c10.1,2.7,22.2,10.6,31.1,16.4c5.1,3.3,9.5,6.2,10.8,6.2c2.5,0,7.3-8.7,9.8-14.2l0.1-0.2l0.2-0.1  c14-3.6,71.3-59.9,73.1-61.8c24.3-38.8,4.7-94.4,4.5-95c-34.2-150-56.7-174.5-60.3-177.6c-2,7.3-17.4,62.7-22.6,66  c-1.5,0.9-3.1,1.7-4.7,2.5c-6.9,3.4-17.3,8.5-16.8,16.9c0.6,10.8-11.8,89.4-12.9,96.3c13,6.2,22.1,38.8,22.5,40.2l0.1,0.2l-0.1,0.2  c-0.6,1-14.5,25.5-19.8,29.7c-4.2,3.3-6.2,41.6-6.6,56.1v0.2l-0.2,0.2C470.6,780,469.5,790.1,469.4,792.2z",
        type: "path",
      },
      {
        id: "body-button-color",
        d: "M388.5,693.7c10.6,32.6-9.9,106.6-9.9,106.6l-9.6-0.2c0,0,2.4-63.9-1.8-102.2s0.5-176,0.5-176  c15.7-90.2-6.6-111.3-6.6-111.3h16.5c1.9,3.2,11.5,7.4,11.5,7.4c6.8,13.1-2.3,111.2-2.3,111.2S377.9,661.1,388.5,693.7z",
        type: "path",
      },
      {
        id: "left-body-color",
        d: "M166,416.2c9.7,17.3,25.2,105.5,22.6,154.5c-1.7,32.9,7.8,58.5,14,75.5c3,8.1,5.2,14,5.2,17.9  c0,13.2,12.4,83.7,21.4,88c9.3,4.4,18.6,44.5,19.9,49.8c12.2-3.2,110.6-2.5,119.4-2.4c0.2-6,2.1-65.6-1.8-101.6l0,0  c-2.2-19.8-1.9-66.6-1.3-102.4c0.3-20.3,0.8-39.2,1.2-51.5c0.5-17.4,0.6-21.2,0.7-22.2l-0.1,0c14.8-85-4.4-108.6-6.3-110.7  c-0.5,0-1,0.1-1.5,0.1c-21.2,0-59.5-31.2-62.7-33.8c-2.6,0.2-30.8,1.8-39.4,2.3C248.9,380.2,173.1,413.1,166,416.2z",
        type: "path",
      },
      {
        id: "left-body-border",
        d: "M248.4,803.2l-0.1-0.6c-0.1-0.5-10.2-45.3-19.4-49.7c-10.1-4.7-22-77.8-22-88.9c0-3.8-2.3-9.9-5.1-17.6  c-6.3-17-15.8-42.8-14.1-75.9c2.6-49.4-13.2-138.5-22.8-154.5l-0.3-0.5l0.5-0.2c3.4-1.5,83.2-36.3,92.3-36.8  c9.1-0.5,39.6-2.3,39.6-2.3l0.2,0l0.2,0.1c0.4,0.4,43.1,35.5,63.9,33.6l0.2,0l0.2,0.2c0.9,0.9,22.2,22.3,6.7,111.7  c0,1.3-4.6,137.9-0.5,175.8l0,0c4.1,37.9,1.8,101.6,1.8,102.3l0,0.5l-0.5,0c-1.1,0-110.7-1-120.1,2.5L248.4,803.2z",
        type: "path",
      },
      {
        id: "right-body-border",
        d: "M442.6,806l-0.4,0c-0.6-0.1-57.8-7.2-63.4-5.2l-0.9,0.3l0.3-0.9c0.2-0.7,20.3-74.2,9.9-106.3  c-10.5-32.3-1.6-163.3-1.5-164.6c0.1-1,9-98.2,2.3-111l-0.2-0.5l38.2-17l0.2,0.1c4,1.2,97.8,29.8,112.1,64.5l0.1,0.2l-0.1,0.2  c-3.3,10.8-19.9,64.7-23,66.2c-5.6,2.8-23.9,13.1-23.2,28.3c0.8,16.5-16.5,121.1-16.7,122.1c-0.2,0.6-16.7,52.2-17,71.2  c-0.4,19.2-16.2,51.8-16.3,52.1L442.6,806z",
        type: "path",
      },
      {
        id: "right-body-color",
        d: "M383,799.4c14.2,0,54.5,4.9,59.1,5.5c1.6-3.4,15.7-33.4,16.1-51.4c0.4-19.2,16.9-70.9,17.1-71.4  c0.2-1,17.4-105.4,16.7-121.8c-0.7-15.9,18-26.4,23.8-29.3c2.2-1.4,14.6-39.9,22.4-65.5c-14.1-33.5-106.6-62.3-111.2-63.7l-0.9,0.4  l-36,16c6.4,14.7-2.1,107-2.4,111c-0.1,1.3-9,132.1,1.5,164.3c9.9,30.5-7.5,98-9.6,106.1C380.2,799.5,381.4,799.4,383,799.4z",
        type: "path",
      },
      {
        id: "right-collar-border",
        d: "M389.3,418.5l-0.2-0.1c-0.4-0.2-9.8-4.2-11.8-7.6l-0.1-0.2l0.2-0.4l13-32.1c0.1-0.2,0.2-0.4,0.4-0.5c1-0.8,2.3-1.2,3.5-1.2  l0.2,0c3.2-0.1,9.8-0.2,16-0.2c5,0,9.7,0,12.1,0.1c0.6,0,1.2,0,1.8,0.2l0.1,0l0.1,0.1c0.7,0.7,1.6,24.9,1.6,25.2l0,0.3L389.3,418.5z",
        type: "path",
      },
      {
        id: "left-collar-border",
        d: "M360.6,411.1c-3.8,0-23.6-1.7-63.9-33.9l-0.3-0.2l0.1-0.3c0.1-0.2,6.5-18,20.7-23.7l0.3-0.1l0.2,0.2  c0.3,0.3,25.3,25.8,46.6,31c1.3,0.3,0.9,1.8,0.8,2.3c-1.2,5.3-3.7,22.9-3.5,24l0.1,0.5l-0.5,0.1C361.2,411.1,361,411.1,360.6,411.1z",
        type: "path",
      },
      {
        id: "left-collar-color",
        d: "M297.6,376.7c39.9,31.8,59.9,33.4,63.1,33.4c0.1-3.5,2.5-19.5,3.5-23.8c0.2-1,0-1.1-0.1-1.1  c-20.5-5-44.2-28.4-46.9-31.1C304.8,359.3,298.5,374.3,297.6,376.7z",
        type: "path",
      },
      {
        id: "right-collar-color",
        d: "M378.3,410.6c1.9,2.8,9.6,6.3,11,6.9l35.9-16c-0.1-3.6-0.9-21.8-1.3-24.1c-0.4-0.1-0.8-0.1-1.2-0.1  c-2.4-0.1-7.1-0.1-12.1-0.1c-6.2,0-12.8,0.1-16,0.2l-0.2,0c-1,0-2.1,0.4-2.9,1c0,0,0,0.1-0.1,0.1l0,0.1L378.3,410.6z",
        type: "path",
      },
      {
        id: "main-collar-color",
        d: "M422.8,376.6C422.8,376.6,422.8,376.7,422.8,376.6L422.8,376.6c-0.9,0.3-6.2,0.2-12.2,0l0,0  c-8.4-0.2-18.2-0.3-19.1,0.8c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0.1-0.2,0.3-0.3,0.5l0,0c-3.6,6-12.1,29.6-13,32l-0.1,0.2h-16.5  l3.3-23.7c0.2-1.1-0.4-2.2-1.4-2.6c-6.7-2.8-31.5-13.9-45.3-30.8c0,0,22.9-35.5,85.9-3.9C403.6,349.6,447,366.1,422.8,376.6z",
        type: "path",
      },
      {
        id: "cap-front",
        d: "M297,253.4c0,0,41.9,27.3,90,25s70.1-20.9,70.1-20.9s21.2,14.7,20.5,21s-6.8,25.6-56.1,27.9  S331.7,300.3,297,253.4z",
        type: "path",
      },
      {
        id: "cap-upper",
        d: "M297,253.4c0,0-10.8-95.8,81.7-106.6c0,0,78.8-5.3,78.4,110.8C457.1,257.5,391.2,305.6,297,253.4z",
        type: "path",
      },
      {
        id: "button-1",
        cx: "377.7",
        cy: "424.3",
        r: "7.7",
        type: "circle",
      },
      {
        id: "button-2",
        cx: "378.7",
        cy: "512.7",
        r: "7.3",
        type: "circle",
      },
      {
        id: "button-3",
        cx: "373.4",
        cy: "588.5",
        r: "7.3",
        type: "circle",
      },
      {
        id: "button-4",
        cx: "376.9",
        cy: "687.5",
        r: "7.3",
        type: "circle",
      },
      {
        id: "button-5",
        cx: "375.7",
        cy: "785.3",
        r: "4.4",
        type: "circle",
      },
      {
        id: "abc",
        d: "M649.9,0.1v1009.8H0.1V0.1H649.9 M650,0H0v1010h650V0z",
        type: "path",
      },
      {
        id: "watermark",
        d: "M134,909.8c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18L131,885l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.2,10.9c-1.5,4.7-3,9.3-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.2,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.5l-3.5-3.6c1.1-2.1,2.1-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7   l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.3   l7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.8,3.6,1.3,5.4c7.2-3,31.3-11.8,33.6-16.3C144.4,935.8,138.1,911,134,909.8z    M96.4,923.8l1,0.4l-3.7,1.6l-1.1-0.6l-1.1-3.8L96.4,923.8z M70.4,916.1l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9   L70.4,916.1z M116.3,940.9l-2-7.6l-9.3,4.1c0.5,2.2,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8l2.5-0.5   c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.6c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.4,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6   c0-0.1-0.1-0.1-0.1-0.2l-13.2,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.2-9.7l3.3,12.4L116.3,940.9z",
        type: "path",
      },
      {
        id: "watermark-1",
        d: "M293.2,750.5c-5-1.4-17,5.7-23.2,8.5c-0.5-2.3-1.1-4.6-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.8   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.4,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.4,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10c1.4,2.4,2.8,4.8,4.2,7.1l4.8-6.4l-3.5-3.6c1.1-2.1,2.2-4.2,3.2-6.3   c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.3-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.7h0.6   c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4   c7.2-3,31.2-11.8,33.6-16.3C303.7,776.5,297.4,751.7,293.2,750.5z M255.6,764.5l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.8L255.6,764.5z    M229.7,756.8l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L229.7,756.8z M275.6,781.6c-0.6-2.5-1.3-5-2-7.6l-9.4,4.1   c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9c0.9-1.9,1.8-3.8,2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1   c-0.4,1.2-0.8,2.3-1.2,3.5c0.9-0.8,1.6-2,2.6-2.2c0.9,0.3,0.4,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2   l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.3l22.1-9.7c1.1,4.1,2.2,8.3,3.3,12.4L275.6,781.6z",
        type: "path",
      },
      {
        id: "watermark-2",
        d: "M452.5,591.3c-5-1.4-17,5.7-23.2,8.5c-0.6-2.3-1.1-4.6-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4   l-4.4-0.8l-1.4-0.7c1.3-1.3,2.7-2.6,4-3.9l0.5-0.2l-6.8-1.9l41-18c-0.8-3.5-1.6-6.9-2.4-10.4l-52.5,23l-12.4,1.6l-6,1.8l-9.2-2.5   c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6   c2.9,1.4,7.4,7.7,9.1,10.9c-1.5,4.7-3,9.3-4.5,13.9c-2.8,3.8-10.2,5.7-13.4,9.1c-1.2,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.5l-3.5-3.6   c1.1-2.1,2.1-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5c0.3,1,0.6,2.1,0.9,3.2c-13.1,5.4-25.6,9.5-36.3,8.3   c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4l7.1-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1   c0.4,1.8,0.8,3.6,1.3,5.4c7.2-3,31.2-11.8,33.6-16.3C463,617.2,456.7,592.4,452.5,591.3z M414.9,605.3l1,0.4l-3.6,1.6l-1.1-0.6   l-1.1-3.8L414.9,605.3z M388.9,597.6l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1l0.8,2.9L388.9,597.6z M434.8,622.3l-2-7.6l-9.4,4.1   c0.5,2.2,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.6   c0.9-0.8,1.6-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2   c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4L434.8,622.3z",
        type: "path",
      },
      {
        id: "watermark-3",
        d: "M611.7,432c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7l4-3.9l0.5-0.2   l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2   c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9c-2.8,3.8-10.2,5.7-13.4,9.1   c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1c1.6-2.1,3.2-4.3,4.8-6.4l-3.5-3.6c1.1-2.1,2.2-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4   l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.5c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4c7.2-3,31.2-11.8,33.6-16.4C622.2,458,615.9,433.2,611.7,432z    M574.1,446l1,0.4l-3.7,1.6l-1.1-0.6l-1.1-3.9L574.1,446z M548.2,438.3l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9   L548.2,438.3z M594.1,463.1c-0.7-2.5-1.3-5-2-7.6l-9.3,4.1c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8   l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1c-0.4,1.2-0.8,2.3-1.2,3.5c0.9-0.9,1.7-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3   c8.3-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.1-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L594.1,463.1z",
        type: "path",
      },
      {
        id: "watermark-4",
        d: "M586.7,630.2c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7l4-3.9l0.5-0.2   l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23L519,630l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2   c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9c-2.8,3.8-10.2,5.7-13.4,9.1   c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1c1.6-2.2,3.2-4.3,4.8-6.4l-3.5-3.6c1.1-2.1,2.2-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4   l16.9-8.5l0.8,3.2c-13.1,5.3-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.5c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4c7.2-3,31.2-11.8,33.6-16.4C597.2,656.2,590.9,631.4,586.7,630.2z    M549.1,644.2l1,0.4l-3.7,1.6l-1.1-0.6l-1.1-3.8L549.1,644.2z M523.2,636.6l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1   c0.2,1,0.5,2,0.7,2.9L523.2,636.6z M569.1,661.3c-0.7-2.5-1.3-5-2-7.6l-9.3,4.1c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9   l-9.6-4l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1c-0.4,1.2-0.8,2.3-1.2,3.5c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3   c8.3-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L569.1,661.3z",
        type: "path",
      },
      {
        id: "watermark-5",
        d: "M116.4,525.3c-5-1.4-17,5.7-23.2,8.5c-0.6-2.3-1.1-4.6-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.3,0.4   l-4.4-0.8l-1.4-0.7l4-3.9l0.4-0.2l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2   l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1c1.6-2.1,3.2-4.3,4.8-6.4l-3.5-3.6l3.2-6.3c1.3-1.9,9.3-1.5,12-1.7   l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.3-25.6,9.5-36.3,8.2c-1.6,4-2,8.3-4.2,12.2l5.7,5.7h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   l7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.9,3.6,1.3,5.4c7.2-3,31.2-11.8,33.6-16.3   C126.9,551.3,120.6,526.5,116.4,525.3z M78.8,539.3l1,0.4l-3.7,1.6l-1.1-0.6c-0.4-1.3-0.8-2.6-1.2-3.8L78.8,539.3z M52.9,531.7   l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L52.9,531.7z M98.8,556.5l-2-7.6l-9.3,4.1c0.5,2.1,1,4.3,1.4,6.4   l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9c0.9-1.9,1.8-3.9,2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.5   c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.4,0.2,0.8,1.3c8.3-3.6,14.4-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2   c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2c-0.2-0.1-0.3-0.2-0.4-0.3l22.2-9.7l3.3,12.4L98.8,556.5z",
        type: "path",
      },
      {
        id: "watermark-6",
        d: "M275.7,366.1c-5-1.4-17,5.7-23.2,8.5l-1.6-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.9-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.4,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.4,4.6   c1.2-0.9,2.2-1.9,3.7-2.1c-0.6-2.4-1.2-4.8-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,10.9l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.5l-3.5-3.6l3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5   l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2.1,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4c7.2-3,31.2-11.8,33.6-16.3C286.2,392,279.9,367.3,275.7,366.1z    M238.1,380.1l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.9L238.1,380.1z M212.2,372.4l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1   c0.2,1,0.5,2,0.7,2.9L212.2,372.4z M258.1,397.2l-2-7.6l-9.4,4.1c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9   l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.5c0.9-0.8,1.6-2,2.6-2.2c0.9,0.3,0.5,0.2,0.8,1.3   c8.4-3.6,14.4-3.1,22.8-5.6c0-0.1-0.1-0.1-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L258.1,397.2z",
        type: "path",
      },
      {
        id: "watermark-7",
        d: "M435,206.8c-5-1.4-17,5.7-23.2,8.5c-0.5-2.3-1.1-4.6-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18L432,182l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.4,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,10.9l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10c1.4,2.4,2.8,4.8,4.2,7.1l4.8-6.4c-1.2-1.2-2.3-2.4-3.5-3.6l3.2-6.3   c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.5   c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4l7.1-9.1c13,0.6,17.5,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.9,3.6,1.3,5.4   c7.2-3,31.2-11.8,33.6-16.4C445.5,232.8,439.1,208,435,206.8z M397.4,220.8l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.9L397.4,220.8z    M371.5,213.1l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L371.5,213.1z M417.4,237.9c-0.7-2.5-1.3-5-2-7.6l-9.4,4.1   c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9c0.9-1.9,1.8-3.9,2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1   l-1.2,3.5c0.9-0.9,1.7-2,2.6-2.2c0.9,0.3,0.5,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.1-0.1-0.2l-13.3,2l-0.1-0.2   c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2c-0.1-0.1-0.3-0.2-0.4-0.4l22.1-9.7c1.1,4.1,2.2,8.3,3.3,12.4L417.4,237.9z",
        type: "path",
      },
      {
        id: "watermark-8",
        d: "M594.2,47.6c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.8-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.2,11c-1.5,4.7-3,9.3-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.4l-3.5-3.7c1.1-2.1,2.1-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7   l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.6-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.8,3.6,1.3,5.4c7.2-3,31.2-11.8,33.6-16.3   C604.7,73.5,598.4,48.7,594.2,47.6z M556.6,61.6l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.8L556.6,61.6z M530.7,53.9l-8.1-1.1   c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L530.7,53.9z M576.6,78.7l-2-7.6l-9.4,4.1c0.5,2.2,1,4.3,1.5,6.4l-18.8,6.1   l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.5c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3   c8.3-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L576.6,78.7z",
        type: "path",
      },
    ],
  },
};
export default fourthSvgData;