import { Button, message, Tooltip } from "antd";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import restartImage from "../../Assets/restart.png";
import { CopyOutlined } from "@ant-design/icons";

const TooltipContent = ({ item, handleCopy }) => (
  <span>
    {item?.ColorCode}
    <CopyOutlined
      style={{ marginLeft: 5 }}
      onClick={(e) => handleCopy(e, item.ColorCode)}
    />
  </span>
);

const ListColor = ({ handleColor, colorsss }) => {
  const { data } = useSelector((state) => state.color);

  const isColorDark = (color) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness < 128;
  };

  const handleCopy = (e, colorCode) => {
    e.stopPropagation();
    navigator.clipboard
      .writeText(colorCode)
      .then(() => {
        message.success("Color code copied to clipboard!");
      })
      .catch(() => {
        message.error("Failed to copy color code.");
      });
  };

  return (
    <Fragment>
      <div className="fleexWrapColor">
        {data &&
          data?.items?.map((item, i) => (
            <Tooltip
              title={<TooltipContent item={item} handleCopy={handleCopy} />}
              placement="top"
            >
              <div key={i} className="cololrnMAe">
                <div
                  className="fleexWrapColorOutsiude"
                  style={{ border: `1px solid #000` }}
                  // style={{ border: `1px solid ${item.ColorCode}` }}
                  onClick={() => handleColor(item.ColorCode)}
                >
                  <div
                    className="fleexWrapColorInside"
                    style={{ backgroundColor: item.ColorCode }}
                  >
                    {item?.ColorCode === colorsss?.fill ? (
                      <img
                        width="25"
                        height="25"
                        src={`https://img.icons8.com/material-rounded/24/checkmark--v1.png`}
                        alt="checkmark--v1"
                        style={{
                          filter: isColorDark(item.ColorCode)
                            ? "invert(1)"
                            : "invert(0)",
                        }}
                      />
                    ) : null}
                  </div>
                </div>
                <p>{item.Name}</p>
              </div>
            </Tooltip>
          ))}
      </div>
      <div className="ceeenter">
        <Button className="restart" onClick={() => handleColor("#fff")}>
          <img src={restartImage} alt="asfd" /> Restart
        </Button>
      </div>
    </Fragment>
  );
};

export default ListColor;