import React, { Fragment } from "react";
import Sidebar from "../Components/Sidebar";
import AddColorModal from "../Components/Colors/AddColorModal";

function AddColor() {
  return (
    <Fragment>
      <div class="container-fluid">
        <div className="row">
          <div className="col-sm-3 sidebarss">
            <Sidebar />
          </div>
          <div className="col-sm-9 ssidebar">
            <AddColorModal />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AddColor;
