import { Button, ColorPicker, Form, Input, Select, message } from 'antd';
import React, { Fragment, useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { postColor } from "../../Redux/Action/color";
import { clearError } from "../../Redux/Reducer/colorReducer";
import { useNavigate } from "react-router-dom";

const AddColorModal = ({ handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postSuccess, postError } = useSelector((state) => state.color);
  const [colorCode, setColorCode] = useState("#ffffff");
  const handleColorChange = (color) => {
    const metaColor = color.metaColor;
    const hexColor = `#${metaColor.toHex()}`;
    setColorCode(hexColor);
  };
  const onFinish = (value) => {
    const values = {
      Name: value.Name,
      ColorCode: colorCode,
      Status: value.Status,
    };

    dispatch(postColor(values));
  };

  const onFinishFailed = (errorInfo) => {
    message.error(errorInfo);
  };

  useEffect(() => {
    if (postError) {
      message.error(postError);
      dispatch(clearError());
    }
    if (postSuccess) {
      message.success(postSuccess);
      dispatch(clearError());
      // handleClose();
      navigate(-1);
    }
  }, [postError, postSuccess]);

  return (
    <Fragment>
      <div className="container-fluid modaal">
        <div className="row">
          <div className="col-sm-12 forrrms close">
            <h2>Add Color</h2>
          </div>
        </div>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          autoComplete="off"
        >
          <div className="row">
            <div className="col-sm-12 forrrms inot">
              <Form.Item
                name="Name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter pattern name!",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </div>
            <div className="col-sm-12 forrrms">
              <Form.Item
                name="ColorCode"
                label="Background Color"
                className="colors"
              >
                <ColorPicker
                  trigger="hover"
                  showText
                  defaultValue={colorCode}
                  color={colorCode}
                  onChange={handleColorChange}
                />
              </Form.Item>
            </div>
            <div className="col-sm-12 forrrms">
              <Form.Item
                name="Status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "Please select pattern status!",
                  },
                ]}
              >
                <Select placeholder="Active/Inactive">
                  <Select.Option value={true}>Active</Select.Option>
                  <Select.Option value={false}>Inactive</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-sm-12 forrrms">
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default AddColorModal;
