import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  startDate: null,
  endDate: null,
  data: [],
};

const dashboardReducerSlice = createSlice({
  name: "dashboardReducer",
  initialState,
  reducers: {
    getDashboardStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getdashboardSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
      state.startDate = action.payload.data.startDate;
      state.endDate = action.payload.data.endDate;
    },
    getdashboardFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  getDashboardStart,
  getdashboardSuccess,
  getdashboardFailure,

  clearError,
} = dashboardReducerSlice.actions;

export default dashboardReducerSlice.reducer;
