import React, { Fragment, useEffect, useState } from "react";
import logo from "../Assets/logoSaddleBattle.png";
import { Link, NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { getMyDetails } from "../Redux/Action/authAdmin";
import { Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";


function Sidebar() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleLogout = () => {
    Cookies.remove("token");
  };
  useEffect(() => {
    dispatch(getMyDetails());
  }, [dispatch]);

  const handleShow = () => {
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row desktttop">
          <div className="col-sm-12">
            <img src={logo} alt="Logo" />
          </div>
          <div className="col-sm-12">
            <ul>
              <li>
                <NavLink to="/dashboard" activeClassName="active">
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink to="/design-management" activeClassName="active">
                  Design Management
                </NavLink>
              </li>
              <li>
                <NavLink to="/color-management" activeClassName="active">
                  Color Management
                </NavLink>
              </li>
              <li>
                <NavLink to="/design-creation" activeClassName="active">
                  Design Creation
                </NavLink>
              </li>
              <li>
                <NavLink to="/profile" activeClassName="active">
                  Profile
                </NavLink>
              </li>
              <li onClick={handleLogout}>
                <NavLink to="/">Logout</NavLink>
              </li>
            </ul>
          </div>
        </div>

        <div className="row mobile">
          <div className="col-sm-6 btnLeft">
            <Link to="/dashboard">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="col-sm-6 butnNav">
            <Button onClick={handleShow}>
              <MenuOutlined style={{color: '#fff', fontSize: '28px'}}/>
            </Button>
          </div>
        </div>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <div className="offCanvasDiv">
              <Link to="/dashboard">
                <img src={logo} alt="Logo" />
              </Link>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
          <div className="row ">
          <div className="col-sm-12">
            <ul>
              <li>
                <NavLink to="/dashboard" activeClassName="active">
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink to="/design-management" activeClassName="active">
                  Design Management
                </NavLink>
              </li>
              <li>
                <NavLink to="/color-management" activeClassName="active">
                  Color Management
                </NavLink>
              </li>
              <li>
                <NavLink to="/design-creation" activeClassName="active">
                  Design Creation
                </NavLink>
              </li>
              <li>
                <NavLink to="/profile" activeClassName="active">
                  Profile
                </NavLink>
              </li>
              <li onClick={handleLogout}>
                <NavLink to="/">Logout</NavLink>
              </li>
            </ul>
          </div>
        </div>
          </Offcanvas.Body>

        </Offcanvas>
      </div>
    </Fragment>
  );
}

export default Sidebar;
