import React, { Fragment } from 'react'
import Sidebar from '../Components/Sidebar'
import HeaderColor from '../Components/Colors/HeaderColor'
import FIlterColor from '../Components/Colors/FIlterColor'
import ColorListing from '../Components/Colors/ColorListing'

function Color() {
    return (
        <Fragment>
            <div class="container-fluid">
                <div className='row'>
                    <div className='col-sm-3 sidebarss'>
                        <Sidebar />
                    </div>
                    <div className='col-sm-9 ssidebar'>
                        <HeaderColor />
                        <FIlterColor />
                        <ColorListing />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Color