import React, { Fragment } from 'react'
import {Tabs} from 'antd'
import BodyMarkingSlider from '../Slider/BodyMarkingSlider';
import ListColor from '../../ColorCompTab/ListColor';
const { TabPane } = Tabs;

const BodyTab = ({handleBodyColor, handleBodyMarkingColor, handleBodyId, bodyColors, bodyMarkingColors}) => {
    return (
        <Fragment>
            <div className="row boddyTab">
                <div className="col-sm-12">
                    <Tabs defaultActiveKey='1'>
                        <TabPane tab="Body Colour"  key="1">
                            <ListColor handleColor={handleBodyColor} colorsss={bodyColors}/>
                        </TabPane>
                        <TabPane tab="Body Marking" key="2" >
                            <BodyMarkingSlider handleBodyId={handleBodyId}/>
                        </TabPane>
                        <TabPane tab="Body Marking Colour" key="3">
                            <ListColor handleColor={handleBodyMarkingColor} colorsss={bodyMarkingColors}/>
                        </TabPane>                
                    </Tabs>        
                </div>
            </div>
        </Fragment>
    )
}

export default BodyTab