import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  editError: null,
  editSuccess: null,
  postError: null,
  postSuccess: null,
  success: null,
  loadings: false,
  deleteSuccess: null,
  deleteError: null,
  data: [],
};

const PatternReducerSlice = createSlice({
  name: "PatternReducer",
  initialState,
  reducers: {
    getPatternReducerStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getPatternReducerSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    getPatternReducerFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    postPatternReducerStart(state, payload) {
      state.loading = true;
      state.postError = null;
    },

    postPatternReducerSuccess(state, action) {
      state.loading = false;
      state.postSuccess = action.payload.message;
    },
    postPatternReducerFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
    },
    editPatternReducerStart(state, payload) {
      state.loadings = true;
      state.editError = null;
    },

    editPatternReducerSuccess(state, action) {
      state.loadings = false;
      state.editSuccess = action.payload.message;
    },
    editPatternReducerFailure(state, action) {
      state.loadings = false;
      state.editError = action.payload;
    },
    deletePatternReducerStart(state, payload) {
      state.loading = true;
      state.deleteError = null;
    },

    deletePatternReducerSuccess(state, action) {
      state.loading = false;
      state.deleteSuccess = action.payload.message;
    },
    deletePatternReducerFailure(state, action) {
      state.loading = false;
      state.deleteError = action.payload;
    },
    clearError: (state) => {
      state.error = null;
      state.success = null;
      state.editSuccess = null;
      state.editError = null;
      state.postSuccess = null;
      state.postError = null;
      state.deleteSuccess = null;
      state.deleteError = null;
    },
  },
});

export const {
  getPatternReducerStart,
  getPatternReducerSuccess,
  getPatternReducerFailure,
  postPatternReducerStart,
  postPatternReducerSuccess,
  postPatternReducerFailure,
  editPatternReducerStart,
  editPatternReducerSuccess,
  editPatternReducerFailure,
  deletePatternReducerStart,
  deletePatternReducerSuccess,
  deletePatternReducerFailure,
  clearError,
} = PatternReducerSlice.actions;

export default PatternReducerSlice.reducer;
