import React, { Fragment } from 'react'
import ResetForrm from '../Components/Reset/ResetForrm'
import logo from "../Assets/logoSaddleBattle.png";

function ResetPassword() {
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row loginBox loginForm">
          <div className="col-sm-5">
            {/* <LoginPage /> */}
            <div className="mainLogo">
              <img src={logo} alt="Logo" />
            </div>
            <ResetForrm />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ResetPassword