import React, { Fragment } from 'react'
import ResetCodes from '../Components/Reset/ResetCodes'

function ResetCode() {
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row loginBox">
                    <div className='col-sm-7'>
                        <ResetCodes />
                    </div>
                </div>    
            </div>
        </Fragment>
    )
}

export default ResetCode