import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { getShirts } from '../../Data'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const ShirtsMap = () => {
    const navigate = useNavigate();
    
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row shirtgs">
                {/* <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                    spaceBetween={20}
                    slidesPerView={4}
                >
                    {getShirts.map((item, i) => (
                        <SwiperSlide>
                            <div className="col-sm-12" key={i}>
                                <img src={item.source} alt={item.name} onClick={() => navigate(`/design-creation/${item.navigation}/${item.id}`)}/>
                                <h1>{item.name}</h1>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper> */}
                {getShirts.map((item, i) => (
              // <SwiperSlide>
                <div className="col-sm-3" key={i}>
                  <img src={item.source} alt={item.name} onClick={() => navigate(`/design-creation/${item.navigation}/${item.id}`)}/>
                  <h1>{item.name}</h1>
                </div>
              // </SwiperSlide>
            ))}

                </div>
            </div>
        </Fragment>
    )
}

export default ShirtsMap