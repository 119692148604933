import { Button, Input, Select, message } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Modal } from "react-bootstrap";
import AddColorModal from "./AddColorModal";
import { useDispatch, useSelector } from "react-redux";
import { getColor } from "../../Redux/Action/color";
import { clearError } from "../../Redux/Reducer/colorReducer";
import { useNavigate } from "react-router-dom";

const FIlterColor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const { success, error } = useSelector((state) => state.color);

  const handleSearchChange = (e) => {
    setName(e.target.value);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleNavigate = () => navigate("/add-color-management");
  useEffect(() => {
    dispatch(getColor({ name, status }));
  }, [dispatch, name, status]);
  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearError());
    }
    if (success) {
      message.success(success);
      dispatch(clearError());
      handleClose();
    }
  }, [error, success]);
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row filter">
          <div className="col-sm-4">
            <Input
              placeholder="Search by name"
              prefix={<SearchOutlined />}
              value={name}
              onChange={handleSearchChange}
            />
          </div>
          <div className="col-sm-4">
            <Select
              placeholder="Active/Inactive"
              value={status}
              onChange={handleStatusChange}
            >
              <Select.Option value={""}>All</Select.Option>
              <Select.Option value={"true"}>Active</Select.Option>
              <Select.Option value={"false"}>Inactive</Select.Option>
            </Select>
          </div>
          <div className="col-sm-4">
            <Button type="primary" onClick={handleNavigate}>
              Add
            </Button>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="AddModal">
        <AddColorModal handleClose={handleClose} />
      </Modal>
    </Fragment>
  );
};

export default FIlterColor;
