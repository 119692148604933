import React, { Fragment } from 'react'
import NewPasswordForm from '../Components/NewPasssword/NewPasswordForm'
import logo from "../Assets/logoSaddleBattle.png";

function NewPassword() {
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row loginBox loginForm">
          <div className="col-sm-5">
            <div className="mainLogo">
              <img src={logo} alt="Logo" />
            </div>
            <NewPasswordForm />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default NewPassword