import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  forgetPasswordFailure,
  forgetPasswordStart,
  forgetPasswordSuccess,
  updatePasswordFailure,
  updatePasswordStart,
  updatePasswordSuccess,
} from "../Reducer/forgetPasswordReducer";
import axios from "axios";

export const forgetPassword = createAsyncThunk(
  "forgetPassword",
  async (values, thunkAPI) => {
    try {
      thunkAPI.dispatch(forgetPasswordStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/forgot/password`,
        values
      );
      const responseData = await response;

      thunkAPI.dispatch(forgetPasswordSuccess(responseData));
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(forgetPasswordFailure(error.response.data.message));

      throw error;
    }
  }
);
export const updatePassword = createAsyncThunk(
  "forgetPassword",
  async ({ token, values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(updatePasswordStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/resetPassword/${token}`,
        values
      );
      const responseData = await response;

      thunkAPI.dispatch(updatePasswordSuccess(responseData));
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(updatePasswordFailure(error.response.data.message));

      throw error;
    }
  }
);
