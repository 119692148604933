import { Button, Form, Input, message } from 'antd';
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword } from "../../Redux/Action/forgetPassword";
import { clearError } from "../../Redux/Reducer/forgetPasswordReducer";
import { useNavigate } from "react-router-dom";

const ResetForrm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { success, error } = useSelector((state) => state.forgetPassword);

  const onFinish = (values) => {
    values.ClientUrl = window.location.origin;

    dispatch(forgetPassword(values));
  };
  const onFinishFailed = (errorInfo) => {
    message.error("Failed");
  };

  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearError());
    }
    if (success) {
      message.success(success);
      dispatch(clearError());
    }
  }, [error, success]);

  return (
    <Fragment>
      <div className="container-fluid">
        <Form
          name="login"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="on"
          layout="vertical"
        >
          <div className="row">
            <div className="col-sm-12">
              <Form.Item
                name="Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                  {
                    type: "email",
                    message: "Please enter valid email address",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>

            <div className="col-sm-12 submit">
              <p>
                Do you want to login ?{" "}
                <b onClick={() => navigate("/")}>Login</b>
              </p>
              <Button htmlType="submit" type="primary">
                Send
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default ResetForrm