import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getshirtTypeStart,
  getshirtTypeSuccess,
  getshirtTypeFailure,
} from "../Reducer/shirtTypeReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const getShirtType = createAsyncThunk(
  "getShirtType",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getshirtTypeStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}ShirtTypeListing`,
        {
          headers: config.headers,
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(getshirtTypeSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getshirtTypeFailure(error.response.data.message));

      throw error;
    }
  }
);
