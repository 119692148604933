import { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import Login from "./Pages/Login";
import ResetPassword from "./Pages/ResetPassword";
import ResetCode from "./Pages/ResetCode";
import NewPassword from "./Pages/NewPassword";
import Dashboard from "./Pages/Dashboard";
import DesignManagement from "./Pages/DesignManagement";
import DesignCreations from "./Pages/DesignCreations";
import DesignManagementTabs from "./Pages/DesignManagementTabs";
import Color from "./Pages/Color";
import Profile from "./Pages/Profile";
import DesignCreationPlainJacket from "./Pages/DesignCreationPlainJacket";
import DesignCreationWhiteJacket from "./Pages/DesignCreationWhiteJacket";
import Cookies from "js-cookie";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
import { getMyDetails } from "./Redux/Action/authAdmin";
import DesignCreationPurpleJacket from "./Pages/DesignCreationPurpleJacket";
import DesignCreationBlueJacket from "./Pages/DesignCreationBlueJacket";
import AddColor from "./Pages/AddColor";
import EditColor from "./Pages/EditColor";

function App() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.authAdmin);
  const token = Cookies.get("token");
  // useEffect(() => {
  //   dispatch(getMyDetails());
  // }, [dispatch]);
  return (
    <Fragment>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/reset" element={<ResetPassword />} />
        <Route exact path="/reset-code/:token" element={<ResetCode />} />
        <Route exact path="/new-password/:token" element={<NewPassword />} />
        <Route
          exact
          path="/dashboard"
          element={
            <ProtectedRoute token={token}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/design-management"
          element={
            <ProtectedRoute token={token}>
              <DesignManagement />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/design-management/:id"
          element={
            <ProtectedRoute token={token}>
              <DesignManagementTabs />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/color-management"
          element={
            <ProtectedRoute token={token}>
              <Color />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/add-color-management"
          element={
            <ProtectedRoute token={token}>
              <AddColor />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/edit-color-management/:id"
          element={
            <ProtectedRoute token={token}>
              <EditColor />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <ProtectedRoute token={token}>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route exact path="/design-creation" element={<DesignCreations />} />
        <Route
          exact
          path="/design-creation/plain-jacket/:id"
          element={
            <ProtectedRoute token={token}>
              <DesignCreationPlainJacket />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/design-creation/white-jacket/:id"
          element={
            <ProtectedRoute token={token}>
              <DesignCreationWhiteJacket />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/design-creation/purple-jacket/:id"
          element={
            <ProtectedRoute token={token}>
              <DesignCreationPurpleJacket />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/design-creation/blue-jacket/:id"
          element={
            <ProtectedRoute token={token}>
              <DesignCreationBlueJacket />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Fragment>
  );
}

export default App;
