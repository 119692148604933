import React, { Fragment } from "react";
import Sidebar from "../Components/Sidebar";
import ShirtsMap from "../Components/DesignCreatiion/ShirtsMap";
import HeaderDesignCreation from "../Components/DesignCreatiion/HeaderDesignCreation";
// import { useDispatch } from "react-redux";
// import { getColor } from "../Redux/Action/color";

function DesignCreations() {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   const status = "true";
  //   dispatch(getColor({status}));
  // }, [dispatch]);
  return (
    <Fragment>
      <div class="container-fluid">
        <div className="row">
          <div className="col-sm-3 sidebarss">
            <Sidebar />
          </div>
          <div className="col-sm-9 ssidebar">
            <HeaderDesignCreation />
            <ShirtsMap />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default DesignCreations;
