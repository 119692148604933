import { Button, Form, Input, message } from 'antd';
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearError } from "../../Redux/Reducer/forgetPasswordReducer";
import { updatePassword } from "../../Redux/Action/forgetPassword";
import { useParams } from "react-router-dom";

const NewPasswordForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateSuccess, updateError } = useSelector(
    (state) => state.forgetPassword
  );
  const { token } = useParams();
  const validatePassword = (_, value) => {
    if (value && value.length < 4) {
      return Promise.reject("Password must be at least 4 characters long.");
    }
    return Promise.resolve();
  };
  const onFinish = (values) => {
    if (values.firstpassword === values.confirmPassword) {
      dispatch(updatePassword({ token, values }));
    } else {
      message.error("Both Password Are Not Matched");
    }
  };
  const onFinishFailed = (errorInfo) => {
    message.error("Failed");
  };
  useEffect(() => {
    if (updateError) {
      message.error(updateError);
      dispatch(clearError());
    }
    if (updateSuccess) {
      message.success(updateSuccess);
      navigate("/");
      dispatch(clearError());
    }
  }, [updateError, updateSuccess]);
  return (
    <Fragment>
      <div className="container-fluid">
        <Form
          name="login"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="on"
          layout="vertical"
        >
          <div className="row">
            <div className="col-sm-12">
              <Form.Item
                name="firstpassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter password",
                  },
                  { validator: validatePassword },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            </div>
            <div className="col-sm-12">
              <Form.Item
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your Password",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("firstpassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("The two passwords do not match");
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
            </div>

            <div className="col-sm-12 submit">
              <p>
                Do you want to login ?{" "}
                <b onClick={() => navigate("/")}>Login</b>
              </p>
              <Button htmlType="submit" type="primary">
                Update
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default NewPasswordForm