import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCapPatternStart,
  getCapPatternSuccess,
  getCapPatternFailure,
} from "../Reducer/getCapPatternReducer";
import axios from "axios";

export const getCapPattern = createAsyncThunk(
  "getCapPattern",
  async (ShirtType, thunkAPI) => {
    try {
      thunkAPI.dispatch(getCapPatternStart());

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}PatternListing?PatternPlace=cap&ShirtType=${ShirtType}&size=100`
      );

      const responseData = await response;

      thunkAPI.dispatch(getCapPatternSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getCapPatternFailure(error.response.data.message));

      throw error;
    }
  }
);