import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPatternReducerStart,
  getPatternReducerSuccess,
  getPatternReducerFailure,
  postPatternReducerFailure,
  postPatternReducerSuccess,
  postPatternReducerStart,
  editPatternReducerFailure,
  editPatternReducerSuccess,
  editPatternReducerStart,
  deletePatternReducerStart,
  deletePatternReducerSuccess,
  deletePatternReducerFailure,
} from "../Reducer/patternReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const getPattern = createAsyncThunk(
  "pattern",
  async ({ page, pageSize, place, status, ShirtType, name }, thunkAPI) => {
    try {
      thunkAPI.dispatch(getPatternReducerStart());
      const queryParams = {
        ...(pageSize && { size: pageSize }),
        ...(status && { Status: status }),
        ...(place && { PatternPlace: place }),
        ...(name && { Name: name }),
        ...(ShirtType && { ShirtType }),
        ...(page && { page }),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}PatternListing`,
        {
          params: queryParams,
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(getPatternReducerSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getPatternReducerFailure(error.response.data.message));

      throw error;
    }
  }
);

export const postPattern = createAsyncThunk(
  "pattern",
  async (formData, thunkAPI) => {
    try {
      thunkAPI.dispatch(postPatternReducerStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}CreatePattern`,

        formData,
        config
      );

      const responseData = await response;

      thunkAPI.dispatch(postPatternReducerSuccess(responseData?.data));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postPatternReducerFailure(error.response.data.message));

      throw error;
    }
  }
);
export const editPattern = createAsyncThunk(
  "PatternReducer",
  async ({ ids, values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(editPatternReducerStart());
      const token = Cookies.get("token");

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}EditPattern/${ids}`,

        values,
        config
      );

      const responseData = await response;

      thunkAPI.dispatch(editPatternReducerSuccess(responseData?.data));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(editPatternReducerFailure(error.response.data.message));

      throw error;
    }
  }
);
export const deletePattern = createAsyncThunk(
  "PatternReducer",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(deletePatternReducerStart());
      const token = Cookies.get("token");

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}DeletePattern/${id}`,

        config
      );

      const responseData = await response;

      thunkAPI.dispatch(deletePatternReducerSuccess(responseData?.data));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(
        deletePatternReducerFailure(error.response.data.message)
      );

      throw error;
    }
  }
);
