import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  updateError: null,
  updateSuccess: null,
};

const forgetPasswordSlice = createSlice({
  name: "forgetPassword",
  initialState,
  reducers: {
    forgetPasswordStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    forgetPasswordSuccess(state, action) {
      state.loading = false;
      state.success = action.payload.data.message;
    },
    forgetPasswordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updatePasswordStart(state, payload) {
      state.loading = true;
      state.updateError = null;
    },

    updatePasswordSuccess(state, action) {
      state.loading = false;
      state.updateSuccess = action.payload.data.message;
    },
    updatePasswordFailure(state, action) {
      state.loading = false;
      state.updateError = action.payload;
    },

    clearError: (state) => {
      state.error = null;
      state.success = null;
      state.updateError = null;
      state.updateSuccess = null;
    },
  },
});

export const {
  forgetPasswordStart,
  forgetPasswordSuccess,
  forgetPasswordFailure,
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordFailure,
  clearError,
} = forgetPasswordSlice.actions;

export default forgetPasswordSlice.reducer;
