import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPattern } from "../../Redux/Action/pattern";
import { useParams } from "react-router-dom";
const PatternFilter = ({
  place,
  page,
  pageSize,
  id,
  showEdit,
  setShowEdit,
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  // const { id } = useParams();
  const [status, setStatus] = useState("");
  const [ShirtType, setShirtType] = useState();

  const { data } = useSelector((state) => state.pattern);

  const handleSearchChange = (e) => {
    setName(e.target.value);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };
  useEffect(() => {
    setShirtType(id);
    dispatch(getPattern({ name, status, ShirtType, place, page, pageSize }));
  }, [name, status, dispatch, ShirtType, page, pageSize, place, showEdit]);

  return (
    <div className="row filter">
      <div className="col-sm-12">
        <h4>No Of Patterns: {data && data?.totalCount} </h4>
      </div>
      <div className="col-sm-3">
        <Input
          placeholder="Search by name"
          prefix={<SearchOutlined />}
          onChange={handleSearchChange}
        />
      </div>
      <div className="col-sm-3">
        <Select
          placeholder="Active/Inactive"
          value={status}
          onChange={handleStatusChange}
        >
          <Select.Option value="">All</Select.Option>
          <Select.Option value="true">Active</Select.Option>
          <Select.Option value="false">Inactive</Select.Option>
        </Select>
      </div>
    </div>
  );
};

export default PatternFilter;
