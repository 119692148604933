import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  authAdminLoginStart,
  authAdminLoginSuccess,
  authAdminLoginFailure,
  getMyDetailsStart,
  getMyDetailsSuccess,
  getMyDetailsFailure,
  updateProfileSuccess,
  updateProfileFailure,
  updateProfileStart,
  changePasswordFailure,
  changePasswordSuccess,
  changePasswordStart,
} from "../Reducer/authAdminReducer";
import Cookies from "js-cookie";

import axios from "axios";
export const Login = createAsyncThunk("authAdmin", async (values, thunkAPI) => {
  try {
    thunkAPI.dispatch(authAdminLoginStart());

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}auth/Login`,
      values
    );
    const responseData = await response;

    thunkAPI.dispatch(authAdminLoginSuccess(responseData));
    Cookies.set("token", responseData.data.token);

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(authAdminLoginFailure(error.response.data.message));

    throw error;
  }
});
export const getMyDetails = createAsyncThunk(
  "authAdmin",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getMyDetailsStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}auth/me`,
        config
      );
      const responseData = await response;
      thunkAPI.dispatch(getMyDetailsSuccess(responseData));
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getMyDetailsFailure(error.response.data.message));

      throw error;
    }
  }
);
export const updateProfile = createAsyncThunk(
  "authAdmin",
  async (values, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateProfileStart());
      const token = Cookies.get("token");

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}auth/EditAdmin`,
        values,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(updateProfileSuccess(responseData));
      thunkAPI.dispatch(getMyDetails());
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(updateProfileFailure(error.response.data.message));

      throw error;
    }
  }
);
export const changePassword = createAsyncThunk(
  "authAdmin",
  async (values, thunkAPI) => {
    try {
      thunkAPI.dispatch(changePasswordStart());
      const token = Cookies.get("token");

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}auth/ChangePassword`,
        values,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(changePasswordSuccess(responseData));
      thunkAPI.dispatch(getMyDetails());
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(changePasswordFailure(error.response.data.message));

      throw error;
    }
  }
);
