import { Button, Form, Input, Select, Upload, message, Modal } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { clearError } from "../../Redux/Reducer/patternReducer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { editPattern } from "../../Redux/Action/pattern";

const EditModal = ({
  patternData,
  patternType,
  designName,
  handleClose,
  place,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { editSuccess, editError } = useSelector((state) => state.pattern);
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const onFinish = (value) => {
    const values = new FormData();
    values.append("Name", value.Name);
    values.append("Status", value.Status);
    values.append("PatternPlace", value.PatternPlace);
    values.append("ShirtType", id);

    const file =
      value?.file.fileList && value?.file?.fileList[0]?.originFileObj;
    if (file) {
      values.append("image", file);
    }
    const ids = patternData._id;
    dispatch(editPattern({ ids, values }));
  };

  useEffect(() => {
    if (editError) {
      message.error(editError);
      dispatch(clearError());
    }
    if (editSuccess) {
      message.success(editSuccess);
      dispatch(clearError());
      handleClose();
    }
  }, [editError, editSuccess]);
  const onFinishFailed = (errorInfo) => {
    message.error("Failed");
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);
  useEffect(() => {
    if (patternData) {
      form.setFieldsValue({
        Name: patternData?.Name,
        Status: patternData?.Status,
        PatternPlace: patternData?.PatternPlace,
        file: patternData?.Image,
      });

      if (patternData?.Image) {
        setFileList([
          {
            uid: "-1",
            name: "pattern.svg",
            status: "done",
            url: patternData?.Image,
          },
        ]);
      }
    }
  }, [patternData, form]);

  return (
    <Fragment>
      <div className="container-fluid modaal">
        <div className="row">
          <div className="col-sm-12 forrrms">
            <h2>
              Edit {patternType} Pattern For {designName}
            </h2>
          </div>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          initialValues={patternData}
        >
          <div className="row">
            <div className="col-sm-8">
              <div className="row noPad">
                <div className="col-sm-12">
                  <h3>{patternData?.patternName} Details</h3>
                </div>
                <div className="col-sm-12 inot">
                  <Form.Item
                    name="Name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter pattern name!",
                      },
                    ]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                </div>
                <div className="col-sm-12">
                  <Form.Item
                    name="Status"
                    label="Status"
                    rules={[
                      {
                        required: true,
                        message: "Please select pattern status!",
                      },
                    ]}
                  >
                    <Select placeholder="Active/Inactive">
                      <Select.Option value={true}>Active</Select.Option>
                      <Select.Option value={false}>Inactive</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm-12">
                  <Form.Item
                    name="PatternPlace"
                    label="Pattern Place"
                    rules={[
                      {
                        required: true,
                        message: "Please select pattern place!",
                      },
                    ]}
                  >
                    <Select defaultValue={place} placeholder="Pattern Place">
                      <Select.Option value="body">Body</Select.Option>
                      <Select.Option value="sleeve">Sleeve</Select.Option>
                      <Select.Option value="cap">Cap</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="row noPad">
                <div className="col-sm-12">
                  <h3>Edit {patternType} Pattern</h3>
                </div>
                <div className="col-sm-12">
                  <Form.Item
                    name="file"
                    rules={[
                      {
                        required: true,
                        message: "Please upload pattern!",
                      },
                    ]}
                  >
                    <Upload
                      action="/upload.do"
                      beforeUpload={() => false}
                      accept="image/svg+xml"
                      name="logo"
                      maxCount={1}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={handleChange}
                      onPreview={handlePreview}
                    >
                      {fileList.length >= 1 ? null : (
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                      )}
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className="col-sm-12 forrrms">
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </div>
        </Form>
      </div>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Fragment>
  );
};

export default EditModal;
