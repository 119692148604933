import React, { Fragment } from "react";
import { useSelector } from "react-redux";

const DataBoxes = () => {
  const { data } = useSelector((state) => state.dashboard);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row dataBox">
          <div className="col-sm-3">
            <p>Design Style</p>
            <h3>{data?.shirtCount}</h3>
          </div>
          <div className="col-sm-3">
            <p>Body Patterns</p>
            <h3>{data?.bodyCount}</h3>
          </div>
          <div className="col-sm-3">
            <p>Sleeves Patterns</p>
            <h3>{data?.sleeveCount}</h3>
          </div>
          <div className="col-sm-3">
            <p>Cap Patterns</p>
            <h3>{data?.capCount}</h3>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DataBoxes;
