import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import { useDispatch } from "react-redux";
import { dashboard } from "../../Redux/Action/dashboard";
import moment from "moment";

const Filter = () => {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const [dates, setDates] = useState([null, null]);

  const disabled30DaysDate = (current) => {
    if (!dates[0]) return false;
    const tooLate = dates[0] && current.diff(dates[0], "days") > 30;
    const tooEarly = dates[0] && dates[0].diff(current, "days") > 30;
    return tooEarly || tooLate;
  };

  const handleDateChange = (dates) => {
    setDates(dates || [null, null]);
    if (dates && dates[0] && dates[1]) {
      const startDate = dates[0].toISOString();
      const endDate = dates[1].toISOString();
      dispatch(dashboard({ startDate, endDate }));
    } else {
      dispatch(dashboard({}));
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12 dashBoard">
          <h2>Welcome to the Dashboard</h2>
        </div>
        <div className="col-sm-12 submit">
          <RangePicker
            disabledDate={disabled30DaysDate}
            onChange={handleDateChange}
            value={dates[0] && dates[1] ? dates : null} // Handle value properly
          />
        </div>
      </div>
    </div>
  );
};

export default Filter;
