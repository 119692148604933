import React, { Fragment } from 'react'
import Sidebar from '../Components/Sidebar'
import Filter from '../Components/DesignManagement/Filter'
import Shirts from '../Components/DesignManagement/Shirts'

function DesignManagement() {
    return (
        <Fragment>
            <div class="container-fluid">
                <div className='row'>
                    <div className='col-sm-3 sidebarss'>
                        <Sidebar />
                    </div>
                    <div className='col-sm-9 ssidebar'>
                        <Filter />
                        <Shirts />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DesignManagement