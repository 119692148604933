import React, { Fragment, useState } from "react";
import Sidebar from "../Components/Sidebar";
import { useParams } from "react-router-dom";
import FilterDesignManagementTabs from "../Components/DesignManagementTabs/FilterDesignManagementTabs";
import { getShirts } from "../Data";
import TabsShirt from "../Components/DesignManagementTabs/TabsShirt";

function DesignManagementTabs() {
  const { id } = useParams();
  const [showEdit, setShowEdit] = useState(false);

  const shirt = getShirts.find((shirt) => shirt.id === id);
  return (
    <Fragment>
      <div class="container-fluid">
        <div className="row">
          <div className="col-sm-3 sidebarss">
            <Sidebar />
          </div>
          <div className="col-sm-9 ssidebar">
            <FilterDesignManagementTabs
              setShowEdit={setShowEdit}
              showEdit={showEdit}
              name={shirt.name}
            />
            <TabsShirt setShowEdit={setShowEdit} showEdit={showEdit} />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default DesignManagementTabs;
