import { Button } from "antd";
import React from "react";

const AddBtn = ({ handleShow, handleClose }) => {
  return (
    <div className="col-sm-3 svgHeader">
      <Button type="primary" onClick={handleShow}>
        Add
      </Button>
    </div>
  );
};

export default AddBtn;
