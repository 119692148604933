import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getColorReducerStart,
  getColorReducerSuccess,
  getColorReducerFailure,
  postColorReducerFailure,
  postColorReducerSuccess,
  postColorReducerStart,
  editColorReducerFailure,
  editColorReducerSuccess,
  editColorReducerStart,
  deleteColorReducerStart,
  deleteColorReducerSuccess,
  deleteColorReducerFailure,
  getSingleColorReducerStart,
  getSingleColorReducerSuccess,
  getSingleColorReducerFailure,
} from "../Reducer/colorReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const getColor = createAsyncThunk(
  "colorReducer",
  async ({ page, pageSize, name, status }, thunkAPI) => {
    try {
      thunkAPI.dispatch(getColorReducerStart());
      const queryParams = {
        ...(pageSize && { size: pageSize }),
        ...(name && { Name: name }),
        ...(status && { Status: status }),
        ...(page && { page }),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}ColorList`,
        {
          params: queryParams,
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(getColorReducerSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getColorReducerFailure(error.response.data.message));

      throw error;
    }
  }
);
export const getSingleColor = createAsyncThunk(
  "colorReducer",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(getSingleColorReducerStart());
      const token = Cookies.get("token");

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}SingleColor/${id}`,
        config
      );

      const responseData = await response;

      thunkAPI.dispatch(getSingleColorReducerSuccess(responseData?.data?.data));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(
        getSingleColorReducerFailure(error.response.data.message)
      );

      throw error;
    }
  }
);

export const postColor = createAsyncThunk(
  "colorReducer",
  async (values, thunkAPI) => {
    try {
      thunkAPI.dispatch(postColorReducerStart());
      const token = Cookies.get("token");

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}CreateColor`,

        values,
        config
      );

      const responseData = await response;

      thunkAPI.dispatch(postColorReducerSuccess(responseData?.data));
      await thunkAPI.dispatch(getColor({}));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postColorReducerFailure(error.response.data.message));

      throw error;
    }
  }
);
export const editColor = createAsyncThunk(
  "colorReducer",
  async ({ id, values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(editColorReducerStart());
      const token = Cookies.get("token");

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}EditColor/${id}`,

        values,
        config
      );

      const responseData = await response;

      thunkAPI.dispatch(editColorReducerSuccess(responseData?.data));
      await thunkAPI.dispatch(getColor({}));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(editColorReducerFailure(error.response.data.message));

      throw error;
    }
  }
);
export const deleteColor = createAsyncThunk(
  "colorReducer",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteColorReducerStart());
      const token = Cookies.get("token");

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}DeleteColor/${id}`,

        config
      );

      const responseData = await response;

      thunkAPI.dispatch(deleteColorReducerSuccess(responseData?.data));
      await thunkAPI.dispatch(getColor({}));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(deleteColorReducerFailure(error.response.data.message));

      throw error;
    }
  }
);
