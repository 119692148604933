import { Button, Form, Input, message } from 'antd';
import React, { Fragment, useEffect } from "react";
import { loginData } from "../../Data";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Login } from "../../Redux/Action/authAdmin";
import { clearState } from "../../Redux/Reducer/authAdminReducer";


const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, success } = useSelector((state) => state.authAdmin);
  const onFinish = async (values) => {
    await dispatch(Login(values));
  };
  const onFinishFailed = (errorInfo) => {
    message.error("Failed");
  };
  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearState());
    }
    if (success) {
      message.success(success);
      navigate("/dashboard");
      dispatch(clearState());
    }
  }, [error, success]);

  return (
    <Fragment>
      <div className="container-fluid">
        <Form
          name="login"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="on"
          layout="vertical"
        >
          <div className="row">
            <div className="col-sm-12">
              <Form.Item
                name="Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                  {
                    type: "email",
                    message: "Please enter valid email address",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>
            <div className="col-sm-12">
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter password",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            </div>

            <div className="col-sm-12 submit">
              <p>
                Forget password ?{" "}
                <b onClick={() => navigate("/reset")}>Reset</b>
              </p>
              <Button htmlType="submit" type="primary">
                Login
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default LoginPage