const newSgvData = {
  svg: {
    elements: [
      {
        id: "left-sleeve",
        d: "M129.3,338.8c0,0.2,0.2,0.4,0.2,0.5s-0.1,0.4-0.1,0.6l-0.3,1.4l-0.5,2.8l-1,5.5l-2,11.1   c-2.6,14.8-5.1,29.6-7,44.4c-1.1,9.2-2.3,18.6-1.7,27.7c0.1,5.9-1.2,11.6-2.6,17.2c-6.2,23.7-16.7,46-23.8,69.2   c-0.6,2.1-1.1,4.2-1.4,6.3c-0.1,1.4-0.1,2.8-0.1,4.1c-1.3,17-4.3,33.8-8,50.4c-1.9,7.9-3.8,15.8-7,23.3c-1.3,2.9-2.3,5.9-3.1,9   c-2.6,10.9-4.4,21.9-5.6,33c-1.5,14.8-3,29.9-1.5,44.7c1.5,7.9-2.5,15.3-7,21.4c-3.2,4.5-7.1,8.7-8.9,13.9c-0.6,1.6-0.6,3.4-0.2,5   c1.5,5.2,5.3,9.4,10.4,11.3c6.5,2.7,13.6,1.3,20.5,1.2c2.4,0.2,4.8,0.5,7.2,1.1c14.8,3.7,28.5,10.2,42,17.1c1.3,0.7,2.7,1.4,4,2.3   c-0.2-0.1,0.8,0.4-0.5-0.2c-0.6-0.2-1.2,0-1.7,0.3l-0.2,0.2c0,0.1,0.1-0.1,0.1-0.2c4.1-12.3,6.6-25.2,9.8-37.8   c2.6-10.9,7.8-32.8,10.6-43.8s5.5-21.9,8.8-32.8c1.2-4.3,2.7-8.4,4.6-12.4c0.4-0.7,0.8-1.3,1.3-1.9c0.1-0.2,0.2-0.2,0.3-0.4   c2.2-5.1,2.8-11,3.7-16.7c1-7.3,1.7-14.8,2.3-22.2c1.2-14.9,2-29.9,2.7-44.8l0.1,0.8l-5.7-26.2   C154.6,463.9,142.6,400.5,129.3,338.8L129.3,338.8z",
        type: "path",
      },
      {
        id: "left-sleeve-border",
        d: "M129.2,338.3c12,52.5,25.5,105.7,37.9,158.2c3.2,13.4,9.4,40.4,12.4,53.8c-0.2,15.3-1.3,30.6-2.5,45.9   c-0.6,7.6-1.3,15-2.4,22.6c-0.9,5.8-1.7,11.6-3.8,17.2c-0.4,0.9-0.8,1.7-1.4,2.5c-0.6,0.7-1.1,1.6-1.5,2.4   c-5.5,13.6-8.8,28.4-12.8,42.6c-5.8,21.8-14.3,57.8-20.9,79c-0.7,1.7-1.3,5.4-4.2,4.8c-1.4-0.7-0.4-0.2-0.8-0.4   c-7.6-4.6-15.4-8.7-23.5-12.3c-8.5-3.8-17-7.4-26.2-8.8c-1.8-0.2-3.6-0.3-5.3-0.1c-12,1.1-24.9-1.7-28.7-14.7   c-3.3-12.8,19.5-23.6,16.1-40.6c-0.5-3.8-0.7-7.6-0.7-11.3c-0.1-18.8,1.3-37.7,4-56.3c0.8-5.7,2-11.3,3.6-16.8l1.1-2.8   c1.7-4.2,3.1-8.6,4.1-13.1c4.2-18.2,7.2-36.6,9.1-55.1c0.4-3.3,0.5-6.5,0.6-9.8c0.3-2.5,0.8-5,1.6-7.4c5-16.2,11.8-31.8,17.6-47.7   c2.6-7,5-14.1,7-21.2c1.4-5.3,2.8-10.6,2.8-16c-0.3-9.5,1.1-19.1,2.5-28.3c2.9-18.5,7-37,11.2-55.2c0.4-1.7,1.6-6.6,2-8.2l0.3-1.4   l0.2-0.7C128.9,338.8,129,338.5,129.2,338.3L129.2,338.3z",
        type: "path",
      },
      {
        id: "right-sleeve-border",
        d: "M520.82,338.32a3.13,3.13,0,0,1,.45.86l.19.74.34,1.37c.39,1.57,1.6,6.53,2,8.21,4.21,18.26,8.34,36.75,11.25,55.23,1.44,9.17,2.85,18.86,2.51,28.31,0,5.39,1.43,10.71,2.84,16,2,7.13,4.44,14.17,7,21.19,5.75,15.92,12.52,31.45,17.56,47.69a39.5,39.5,0,0,1,1.56,7.4c.11,3.28.19,6.51.56,9.79a452,452,0,0,0,9.13,55.09,86.88,86.88,0,0,0,4.12,13.08l1.07,2.82A120.18,120.18,0,0,1,585,622.86a377.72,377.72,0,0,1,4,56.32,91.74,91.74,0,0,1-.71,11.35c-3.41,16.94,19.37,27.82,16.11,40.57-3.73,13-16.64,15.75-28.65,14.69a24.48,24.48,0,0,0-5.35.09c-9.13,1.37-17.72,5-26.18,8.8a229.07,229.07,0,0,0-23.5,12.3c-.33.16.6-.27-.76.38-2.84.65-3.45-3.11-4.17-4.77-6.59-21.18-15.08-57.16-20.9-79-3.91-14.2-7.27-29-12.75-42.6a9.92,9.92,0,0,0-1.48-2.43,12.16,12.16,0,0,1-1.4-2.51c-2.15-5.64-2.93-11.42-3.84-17.21-1.08-7.53-1.78-15-2.42-22.56-1.18-15.29-2.28-30.59-2.47-45.89,3-13.4,9.24-40.44,12.43-53.81,12.38-52.5,25.9-105.71,37.92-158.23Z",
        type: "path",
      },
      {
        id: "right-sleeve",
        d: "M520.83,338.92C507.47,400.59,495.44,464,482.26,525.53l-5.69,26.19.08-.79c.64,14.92,1.47,29.9,2.67,44.79.61,7.44,1.34,14.87,2.32,22.21.87,5.63,1.45,11.53,3.69,16.67.1.21.2.23.27.43a9.78,9.78,0,0,1,1.33,1.94,84.22,84.22,0,0,1,4.56,12.44c3.26,10.84,6,21.8,8.8,32.75s8,32.89,10.58,43.79c3.16,12.58,5.7,25.45,9.75,37.75,0,.13.16.32.11.25l-.23-.24a1.93,1.93,0,0,0-1.72-.33c-1.25.61-.24.1-.47.2,1.33-.83,2.65-1.54,4-2.27,13.49-6.94,27.19-13.44,42-17.14a45.24,45.24,0,0,1,7.18-1.09c6.9.07,14,1.41,20.55-1.25a17,17,0,0,0,10.4-11.3,8.1,8.1,0,0,0-.15-5c-1.83-5.23-5.75-9.34-8.93-13.89-4.52-6.09-8.55-13.55-7-21.43,1.52-14.75,0-29.91-1.47-44.66a257.65,257.65,0,0,0-5.58-33.05,51.67,51.67,0,0,0-3.09-9c-3.25-7.54-5.07-15.4-7-23.31-3.69-16.63-6.72-33.37-8-50.41-.08-1.35,0-2.77-.14-4.14a37.9,37.9,0,0,0-1.37-6.34c-7.12-23.18-17.62-45.51-23.81-69.17-1.43-5.6-2.75-11.36-2.64-17.25.58-9.17-.62-18.53-1.73-27.73-1.91-14.84-4.4-29.65-7-44.4l-2-11.07-1-5.54-.51-2.76-.26-1.38c0-.21-.11-.52-.12-.64s.12-.26.15-.51Z",
        type: "path",
      },
      {
        id: "body-border",
        d: "M130.94,338.84a2.86,2.86,0,0,1,.69-.41l.55-.28,1.1-.55c16.85-8.38,34.07-16.62,51.38-24,7.85-3.17,15.7-6.79,24.14-8.41,1-.15,3.33-.15,4.45-.21,49.14-2.17,98.57-4.27,147.76-4.51,23-.07,46.22.11,69.17,2.12l10,1.31.21,0c23.86,9.32,47.65,18.58,71.07,29.24,3.62,1.8,7.69,3.36,10.92,6.11a2.4,2.4,0,0,1,.26.3,2.93,2.93,0,0,1,.41.69,3.54,3.54,0,0,1,.2,1.64l0,.4,0,.33C510,406.79,489,489.15,474.41,553.36c-.06,60.78.13,124,2.23,185.5.34,7.68.57,15.39,1.41,23l.2,1.57q.15.66.27,1.32l.49,2.54c-100.73,5.4-201.91,5.73-302.69,1.28l-3.06-.13,0-3.1c0-61,1.9-148.63,0-208.51a25.86,25.86,0,0,0-.37-3.17c-1.35-5.41-2.45-10.89-3.57-16.29-13-64.33-26.57-134-38.43-198.54Z",
        type: "path",
      },
      {
        id: "body",
        d: "M131.48,339.11c15.18,68.29,30.26,142,45.48,210.35l.79,3.12.23,1.12c.46,3.14.52,6.45.69,9.58,2,67.36,1.34,134.75,1,202.12l-3.05-3.22c99.8,4.66,200,4.91,299.81,1.14L474.58,766l-.39-1.93c-.94-8.33-1-16.72-1.24-25.08-1-49.06-.31-135.66-.34-185.83,4.2-18.67,12.86-56,16.94-74.68,9.74-45.42,20.47-90.7,28.81-136.38l0-.27v-.18c0-.05,0-.16,0-.15a1.83,1.83,0,0,0,.36,1.06,82.76,82.76,0,0,0-9.44-4.83c-23.17-10.24-47-19.4-70.63-28.63l.5.14L429.63,308c-9.52-.89-19.82-1.5-29.31-1.81-51.66-1.55-105.62,0-157.44,1.16-8.24.28-25,.58-33.19.8a30.49,30.49,0,0,0-5.71,1.17c-12.47,3.7-24.62,8.93-36.64,14-11.09,4.71-22.76,10-33.76,15l-1.12.52-.56.26c-.19.06-.41.24-.42.12Z",
        type: "path",
      },
      {
        id: "cap-border",
        d: "M604.2,229.91c.07,22-1.09,35-17,51.6-2.64,3-4.41,6.93-6.76,11.11a32.76,32.76,0,0,1-10,11.53c-8.3,5.64-18.75,6.89-28.43,7.08-13.61-.11-30.5-1.09-41.06-10.81-3.62-3.32-5.81-7.95-7.6-12.31-1.33-3.08-2.57-6-5-8.1-14.82-11.82-19.44-19.86-20.66-38.94-1.17-14.11-1.47-28.74,4.31-42,13.82-33.22,55.29-43.23,87.5-34.23,30.78,8.47,45.18,34.48,44.75,65.11Z",
        type: "path",
      },
      {
        id: "cap",
        d: "M603.3,229.91c.07-41.45-33.85-60.83-71.95-58.42-42,2.73-60.81,28.82-59.73,69.43-.21,18,4.23,26.18,18.58,36.81,6.29,5.2,7.19,14.44,13.73,19.31,10.35,7.69,25.26,6.74,37.79,6.44C550,303,559,302,566,297.53a27.11,27.11,0,0,0,8.18-8.73c2.76-4.44,6.43-10.35,11.19-13.93,15.32-12.8,18-25.71,17.95-45Z",
        type: "path",
      },
      {
        id: "abc",
        d: "M649.9.1V1009.9H.1V.1H649.9M650,0H0V1010H650V0Z",
        type: "path",
      },
      {
        id: "watermark",
        d: "M134,909.8c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18L131,885l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.2,10.9c-1.5,4.7-3,9.3-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.2,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.5l-3.5-3.6c1.1-2.1,2.1-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7   l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.3   l7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.8,3.6,1.3,5.4c7.2-3,31.3-11.8,33.6-16.3C144.4,935.8,138.1,911,134,909.8z    M96.4,923.8l1,0.4l-3.7,1.6l-1.1-0.6l-1.1-3.8L96.4,923.8z M70.4,916.1l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9   L70.4,916.1z M116.3,940.9l-2-7.6l-9.3,4.1c0.5,2.2,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8l2.5-0.5   c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.6c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.4,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6   c0-0.1-0.1-0.1-0.1-0.2l-13.2,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.2-9.7l3.3,12.4L116.3,940.9z",
        type: "path",
      },
      {
        id: "watermark-1",
        d: "M293.2,750.5c-5-1.4-17,5.7-23.2,8.5c-0.5-2.3-1.1-4.6-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.8   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.4,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.4,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10c1.4,2.4,2.8,4.8,4.2,7.1l4.8-6.4l-3.5-3.6c1.1-2.1,2.2-4.2,3.2-6.3   c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.3-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.7h0.6   c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4   c7.2-3,31.2-11.8,33.6-16.3C303.7,776.5,297.4,751.7,293.2,750.5z M255.6,764.5l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.8L255.6,764.5z    M229.7,756.8l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L229.7,756.8z M275.6,781.6c-0.6-2.5-1.3-5-2-7.6l-9.4,4.1   c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9c0.9-1.9,1.8-3.8,2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1   c-0.4,1.2-0.8,2.3-1.2,3.5c0.9-0.8,1.6-2,2.6-2.2c0.9,0.3,0.4,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2   l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.3l22.1-9.7c1.1,4.1,2.2,8.3,3.3,12.4L275.6,781.6z",
        type: "path",
      },
      {
        id: "watermark-2",
        d: "M452.5,591.3c-5-1.4-17,5.7-23.2,8.5c-0.6-2.3-1.1-4.6-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4   l-4.4-0.8l-1.4-0.7c1.3-1.3,2.7-2.6,4-3.9l0.5-0.2l-6.8-1.9l41-18c-0.8-3.5-1.6-6.9-2.4-10.4l-52.5,23l-12.4,1.6l-6,1.8l-9.2-2.5   c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6   c2.9,1.4,7.4,7.7,9.1,10.9c-1.5,4.7-3,9.3-4.5,13.9c-2.8,3.8-10.2,5.7-13.4,9.1c-1.2,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.5l-3.5-3.6   c1.1-2.1,2.1-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5c0.3,1,0.6,2.1,0.9,3.2c-13.1,5.4-25.6,9.5-36.3,8.3   c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4l7.1-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1   c0.4,1.8,0.8,3.6,1.3,5.4c7.2-3,31.2-11.8,33.6-16.3C463,617.2,456.7,592.4,452.5,591.3z M414.9,605.3l1,0.4l-3.6,1.6l-1.1-0.6   l-1.1-3.8L414.9,605.3z M388.9,597.6l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1l0.8,2.9L388.9,597.6z M434.8,622.3l-2-7.6l-9.4,4.1   c0.5,2.2,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.6   c0.9-0.8,1.6-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2   c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4L434.8,622.3z",
        type: "path",
      },
      {
        id: "watermark-3",
        d: "M611.7,432c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7l4-3.9l0.5-0.2   l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2   c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9c-2.8,3.8-10.2,5.7-13.4,9.1   c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1c1.6-2.1,3.2-4.3,4.8-6.4l-3.5-3.6c1.1-2.1,2.2-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4   l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.5c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4c7.2-3,31.2-11.8,33.6-16.4C622.2,458,615.9,433.2,611.7,432z    M574.1,446l1,0.4l-3.7,1.6l-1.1-0.6l-1.1-3.9L574.1,446z M548.2,438.3l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9   L548.2,438.3z M594.1,463.1c-0.7-2.5-1.3-5-2-7.6l-9.3,4.1c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8   l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1c-0.4,1.2-0.8,2.3-1.2,3.5c0.9-0.9,1.7-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3   c8.3-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.1-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L594.1,463.1z",
        type: "path",
      },
      {
        id: "watermark-4",
        d: "M586.7,630.2c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7l4-3.9l0.5-0.2   l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23L519,630l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2   c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9c-2.8,3.8-10.2,5.7-13.4,9.1   c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1c1.6-2.2,3.2-4.3,4.8-6.4l-3.5-3.6c1.1-2.1,2.2-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4   l16.9-8.5l0.8,3.2c-13.1,5.3-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.5c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4c7.2-3,31.2-11.8,33.6-16.4C597.2,656.2,590.9,631.4,586.7,630.2z    M549.1,644.2l1,0.4l-3.7,1.6l-1.1-0.6l-1.1-3.8L549.1,644.2z M523.2,636.6l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1   c0.2,1,0.5,2,0.7,2.9L523.2,636.6z M569.1,661.3c-0.7-2.5-1.3-5-2-7.6l-9.3,4.1c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9   l-9.6-4l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1c-0.4,1.2-0.8,2.3-1.2,3.5c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3   c8.3-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L569.1,661.3z",
        type: "path",
      },
      {
        id: "watermark-5",
        d: "M116.4,525.3c-5-1.4-17,5.7-23.2,8.5c-0.6-2.3-1.1-4.6-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.3,0.4   l-4.4-0.8l-1.4-0.7l4-3.9l0.4-0.2l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2   l6.3,4.6c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,11l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1c1.6-2.1,3.2-4.3,4.8-6.4l-3.5-3.6l3.2-6.3c1.3-1.9,9.3-1.5,12-1.7   l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.3-25.6,9.5-36.3,8.2c-1.6,4-2,8.3-4.2,12.2l5.7,5.7h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   l7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.9,3.6,1.3,5.4c7.2-3,31.2-11.8,33.6-16.3   C126.9,551.3,120.6,526.5,116.4,525.3z M78.8,539.3l1,0.4l-3.7,1.6l-1.1-0.6c-0.4-1.3-0.8-2.6-1.2-3.8L78.8,539.3z M52.9,531.7   l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L52.9,531.7z M98.8,556.5l-2-7.6l-9.3,4.1c0.5,2.1,1,4.3,1.4,6.4   l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9c0.9-1.9,1.8-3.9,2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.5   c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.4,0.2,0.8,1.3c8.3-3.6,14.4-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2   c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2c-0.2-0.1-0.3-0.2-0.4-0.3l22.2-9.7l3.3,12.4L98.8,556.5z",
        type: "path",
      },
      {
        id: "watermark-6",
        d: "M275.7,366.1c-5-1.4-17,5.7-23.2,8.5l-1.6-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.9-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.4,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.4,4.6   c1.2-0.9,2.2-1.9,3.7-2.1c-0.6-2.4-1.2-4.8-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,10.9l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.5l-3.5-3.6l3.2-6.3c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5   l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2.1,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1l1.3,5.4c7.2-3,31.2-11.8,33.6-16.3C286.2,392,279.9,367.3,275.7,366.1z    M238.1,380.1l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.9L238.1,380.1z M212.2,372.4l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1   c0.2,1,0.5,2,0.7,2.9L212.2,372.4z M258.1,397.2l-2-7.6l-9.4,4.1c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9   l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.5c0.9-0.8,1.6-2,2.6-2.2c0.9,0.3,0.5,0.2,0.8,1.3   c8.4-3.6,14.4-3.1,22.8-5.6c0-0.1-0.1-0.1-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L258.1,397.2z",
        type: "path",
      },
      {
        id: "watermark-7",
        d: "M435,206.8c-5-1.4-17,5.7-23.2,8.5c-0.5-2.3-1.1-4.6-1.7-6.9l-9.6,4.2l1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18L432,182l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.4,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.7-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.1,10.9l-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10c1.4,2.4,2.8,4.8,4.2,7.1l4.8-6.4c-1.2-1.2-2.3-2.4-3.5-3.6l3.2-6.3   c1.3-1.9,9.3-1.5,12-1.7l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.5-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.5   c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4l7.1-9.1c13,0.6,17.5,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.9,3.6,1.3,5.4   c7.2-3,31.2-11.8,33.6-16.4C445.5,232.8,439.1,208,435,206.8z M397.4,220.8l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.9L397.4,220.8z    M371.5,213.1l-8.1-1.1c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L371.5,213.1z M417.4,237.9c-0.7-2.5-1.3-5-2-7.6l-9.4,4.1   c0.5,2.1,1,4.3,1.5,6.4l-18.8,6.1l-9.7-4.2l11,0.9l-9.6-3.9c0.9-1.9,1.8-3.9,2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1   l-1.2,3.5c0.9-0.9,1.7-2,2.6-2.2c0.9,0.3,0.5,0.2,0.8,1.3c8.4-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.1-0.1-0.2l-13.3,2l-0.1-0.2   c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2c-0.1-0.1-0.3-0.2-0.4-0.4l22.1-9.7c1.1,4.1,2.2,8.3,3.3,12.4L417.4,237.9z",
        type: "path",
      },
      {
        id: "watermark-8",
        d: "M594.2,47.6c-5-1.4-17,5.7-23.2,8.5l-1.7-6.9l-9.6,4.2c0.4,1.6,0.9,3.2,1.3,4.9l-0.4,0.4l-4.4-0.8l-1.4-0.7   l4-3.9l0.5-0.2l-6.8-1.9l41.1-18l-2.4-10.4l-52.5,23l-12.3,1.6l-6,1.8l-9.2-2.5c5.6,12-0.2,20.2-1.2,30.2l6.3,4.6   c1.2-0.9,2.2-1.9,3.7-2.2c-0.6-2.4-1.2-4.8-1.8-7.1c6-2.7,10.8-5.5,8.5-11.6c2.9,1.4,7.4,7.7,9.2,11c-1.5,4.7-3,9.3-4.5,13.9   c-2.8,3.8-10.2,5.7-13.4,9.1c-1.3,1.4-2.3,7.8-3.5,10l4.2,7.1l4.8-6.4l-3.5-3.7c1.1-2.1,2.1-4.2,3.2-6.3c1.3-1.9,9.3-1.5,12-1.7   l14.9-1.4l16.9-8.5l0.8,3.2c-13.1,5.4-25.6,9.6-36.3,8.3c-1.6,4-2,8.3-4.2,12.2l5.7,5.6h0.6c0.6-2,1.2-4,1.8-5.9l-3.4-0.4l-0.4-0.4   c2.4-3,4.8-6.1,7.2-9.1c13,0.6,17.4,1.1,29.1-9.2l0.2-0.1c0.4,1.8,0.8,3.6,1.3,5.4c7.2-3,31.2-11.8,33.6-16.3   C604.7,73.5,598.4,48.7,594.2,47.6z M556.6,61.6l1,0.4l-3.6,1.6l-1.1-0.6l-1.1-3.8L556.6,61.6z M530.7,53.9l-8.1-1.1   c5.8-0.8,11.7-0.4,17.3,1.1c0.2,1,0.5,2,0.7,2.9L530.7,53.9z M576.6,78.7l-2-7.6l-9.4,4.1c0.5,2.2,1,4.3,1.5,6.4l-18.8,6.1   l-9.7-4.2l11,0.9l-9.6-4l2.6-5.8l2.5-0.5c0.1,0.5,0.2,1,0.4,1.5l-1.6,0.1l-1.2,3.5c0.9-0.8,1.7-2,2.6-2.2c0.8,0.3,0.5,0.2,0.8,1.3   c8.3-3.6,14.5-3.1,22.8-5.6c0-0.1-0.1-0.2-0.1-0.2l-13.3,2l-0.1-0.2c8.1-3.5,14.2-1.9,21.5-3.7l-9-1.2l-0.4-0.4l22.1-9.7l3.3,12.4   L576.6,78.7z",
        type: "path",
      },
    ],
  },
};
export default newSgvData;