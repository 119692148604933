import React, { Fragment } from 'react'

const Filter = () => {

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 dashBoard">
                        <h2>Design Management</h2>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Filter
