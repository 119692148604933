import { Button, Form, Input, message } from "antd";
import React, { Fragment, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../Redux/Action/authAdmin";
import { clearState } from "../../Redux/Reducer/authAdminReducer";

const ChangePasswordModal = ({ handleClose }) => {
  const dispatch = useDispatch();
  const { data, passwordSuccess, passwordError } = useSelector(
    (state) => state.authAdmin
  );
  const validatePassword = (_, value) => {
    if (value && value.length < 4) {
      return Promise.reject("Password must be at least 4 characters long.");
    }
    return Promise.resolve();
  };
  const onFinish = (values) => {
    dispatch(changePassword(values));
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Failed");
  };
  useEffect(() => {
    if (passwordError) {
      message.error(passwordError);
      dispatch(clearState());
    }
    if (passwordSuccess) {
      message.success(passwordSuccess);
      dispatch(clearState());
      handleClose();
    }
  }, [passwordSuccess, passwordError, dispatch]);

  return (
    <Fragment>
      <div className="container-fluid modaal chngPassword">
        <div className="row">
          <div className="col-sm-12 forrrms close">
            <h2>Change Password</h2>
            <CloseOutlined onClick={handleClose} />
          </div>
        </div>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <div className="row">
            <div className="col-sm-12 forrrms inot">
              <Form.Item
                name="previouspassword"
                label="Old Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter old password!",
                  },
                ]}
              >
                <Input placeholder="Old Password" />
              </Form.Item>
            </div>

            <div className="col-sm-12 forrrms inot">
              <Form.Item
                name="newpassword"
                label="New Password"
                rules={[
                  { required: true, message: "Please input your password!" },
                  { validator: validatePassword },
                ]}
              >
                <Input placeholder="New Password" />
              </Form.Item>
            </div>

            <div className="col-sm-12 forrrms inot">
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your Password",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newpassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("The two passwords do not match");
                    },
                  }),
                ]}
              >
                <Input placeholder="Confirm Password" />
              </Form.Item>
            </div>

            <div className="col-sm-12 forrrms">
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default ChangePasswordModal;
