import React, { Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { useSelector } from "react-redux";
import fourthSvgData from "../../../Data/Svg3";
import { Button } from "antd";
import restartImage from '../../../Assets/restart.png'

const ShirtThreeBodyMarkingSlider = ({ handleBodyId }) => {
    const {data } = useSelector((state)=> state.getBodyPattern)

    const sleeveColors = {
        fill: "#FFFFFF",
        stroke: '#1c1a00',
        fillRule: 'nonzero',
        strokeMiterlimit: '10'
    };
    const bodyColors = {
        fill: "#FFFFFF",
        stroke: '#1c1a00',
        fillRule: 'nonzero',
        strokeMiterlimit: '10'
    };
    const capColors = {
        fill: "#FFFFFF",
        stroke: '#1c1a00',
        fillRule: 'nonzero',
        strokeMiterlimit: '10'
    };
    const waterMark = {
      fillRule: "evenodd",
      opacity: 0.15,
    };

    return (
      <Fragment>
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            575: {
              slidesPerView: 1,
            },
            821: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1400: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
          {data?.items?.map((items) => (
            <SwiperSlide key={items._id}>
              <svg
                onClick={() => handleBodyId(items._id)}
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 650 1010"
                width="100%"
                height="auto"
              >
                {fourthSvgData?.svg.elements?.map((item) => (
                  <g data-name="Layer 1">
                    {item.id == "body-button-color" && (
                      <g id="body-button-color">
                        <path style={bodyColors} d={item.d} />
                      </g>
                    )}
                    {item.id == "left-body-border" && (
                      <g id="left-body-color">
                        <path style={bodyColors} d={item.d} />
                      </g>
                    )}
                    {item.id == "left-body-color" && (
                      <g id="left-body-color">
                        <path style={bodyColors} d={item.d} />
                      </g>
                    )}
                    {item.id == "right-body-border" && (
                      <g id="left-body-color">
                        <path style={bodyColors} d={item.d} />
                      </g>
                    )}
                    {item.id == "right-body-color" && (
                      <g id="right-body-color">
                        <path style={bodyColors} d={item.d} />
                      </g>
                    )}

                    {item.id == "right-sleeve-border" && (
                      <g id="right-sleeve-border">
                        <path d={item.d} />
                      </g>
                    )}

                    {item.id == "right-sleeve-color" && (
                      <g id="right-sleeve-color">
                        <path style={sleeveColors} d={item.d} />
                      </g>
                    )}

                    {item.id == "left-collar-color" && (
                      <g id="left-collar-color">
                        <path style={bodyColors} d={item.d} />
                      </g>
                    )}
                    {item.id == "left-collar-border" && (
                      <g id="left-body-color">
                        <path style={bodyColors} d={item.d} />
                      </g>
                    )}
                    {item.id == "right-collar-border" && (
                      <g id="left-body-color">
                        <path style={bodyColors} d={item.d} />
                      </g>
                    )}
                    {item.id == "right-collar-color" && (
                      <g id="right-collar-color">
                        <path style={bodyColors} d={item.d} />
                      </g>
                    )}

                    {item.id == "main-collar-color" && (
                      <g id="main-collar-color">
                        <path style={bodyColors} d={item.d} />
                      </g>
                    )}
                    <g>
                      {item.id == "cap-front" && (
                        <g id="cap-front">
                          <path style={capColors} d={item.d} />
                        </g>
                      )}

                      {item.id == "cap-upper" && (
                        <g id="cap-upper">
                          <path style={capColors} d={item.d} />
                        </g>
                      )}
                    </g>

                    {item.id == "abc" && (
                      <g id="abc">
                        <path d={item.d} />
                      </g>
                    )}

                    {item.id == "button-1" && (
                      <g id="button-1">
                        <circle cx={item.cx} cy={item.cy} r={item.r} />
                      </g>
                    )}

                    {item.id == "button-2" && (
                      <g id="button-2">
                        <circle cx={item.cx} cy={item.cy} r={item.r} />
                      </g>
                    )}
                    {item.id == "button-3" && (
                      <g id="button-3">
                        <circle cx={item.cx} cy={item.cy} r={item.r} />
                      </g>
                    )}

                    {item.id == "button-4" && (
                      <g id="button-4">
                        <circle cx={item.cx} cy={item.cy} r={item.r} />
                      </g>
                    )}

                    {item.id == "button-5" && (
                      <g id="button-5">
                        <circle cx={item.cx} cy={item.cy} r={item.r} />
                      </g>
                    )}
                  </g>
                ))}

                <svg
                  className="paapttern"
                  style={{ strokeWidth: "3" }}
                  dangerouslySetInnerHTML={{
                    __html: `${items.SvgPath?.replace(/^\"|\"$/g, "")}`,
                  }}
                />
                {fourthSvgData?.svg.elements?.map((item) => (
                  <g>
                    {item.id == "left-sleeve-border" && (
                      <g id="left-sleeve-border">
                        <path d={item.d} />
                      </g>
                    )}

                    {item.id == "left-sleeve-color" && (
                      <g id="left-sleeve-color">
                        <path style={sleeveColors} d={item.d} />
                      </g>
                    )}
                  </g>
                ))}
                <g id="eeellleBody">
                  {fourthSvgData?.svg.elements?.map((item) => (
                    <g>
                      {item.id == "watermark" && (
                        <g id="watermark">
                          <path style={waterMark} d={item.d} id="eeellleBody" />
                        </g>
                      )}

                      {item.id == "watermark-1" && (
                        <g id="watermark-1">
                          <path style={waterMark} d={item.d} id="eeellleBody" />
                        </g>
                      )}

                      {item.id == "watermark-2" && (
                        <g id="watermark-2">
                          <path style={waterMark} d={item.d} id="eeellleBody" />
                        </g>
                      )}

                      {item.id == "watermark-3" && (
                        <g id="watermark-3">
                          <path style={waterMark} d={item.d} id="eeellleBody" />
                        </g>
                      )}

                      {item.id == "watermark-4" && (
                        <g id="watermark-4">
                          <path style={waterMark} d={item.d} id="eeellleBody" />
                        </g>
                      )}

                      {item.id == "watermark-5" && (
                        <g id="watermark-5">
                          <path style={waterMark} d={item.d} id="eeellleBody" />
                        </g>
                      )}

                      {item.id == "watermark-6" && (
                        <g id="watermark-6">
                          <path style={waterMark} d={item.d} id="eeellleBody" />
                        </g>
                      )}

                      {item.id == "watermark-7" && (
                        <g id="watermark-7">
                          <path style={waterMark} d={item.d} id="eeellleBody" />
                        </g>
                      )}

                      {item.id == "watermark-8" && (
                        <g id="watermark-8">
                          <path style={waterMark} d={item.d} id="eeellleBody" />
                        </g>
                      )}
                    </g>
                  ))}
                </g>
              </svg>
              <p className="patternName">{items.Name}</p>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="ceeenter">
          <Button className="restart" onClick={() => handleBodyId("")}>
            <img src={restartImage} alt="asfd" /> Reset Pattern
          </Button>
        </div>
      </Fragment>
    );
};

export default ShirtThreeBodyMarkingSlider;