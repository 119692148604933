import React, { Fragment } from 'react'
import Sidebar from '../Components/Sidebar'
import Shirts from '../Components/DesignCreationPlainJackets/Shirts'
import HeaderDesignCreationPlainJacket from '../Components/DesignCreationPlainJackets/HeaderDesignCreationPlainJacket'

function DesignCreationPlainJacket() {
    return (
        <Fragment>
            <div class="container-fluid">
                <div className='row'>
                    <div className='col-sm-3 sidebarss'>
                        <Sidebar />
                    </div>
                    <div className='col-sm-9 ssidebar'>
                        <HeaderDesignCreationPlainJacket />
                        <Shirts />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DesignCreationPlainJacket