import React, { Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import svgData from '../../../Data/svg'
import { useSelector } from "react-redux";
import { Button } from "antd";
import restartImage from '../../../Assets/restart.png'

const SleeveMarkingSlider = ({ handleSleeveId }) => {
    const {data , error} = useSelector((state)=> state.getSleevePattern)
    const waterMark = {
      fillRule: "evenodd",
      opacity: 0.15,
    };
    const sleeveColors = {
      fill: "#FFFFFF",
    };

    const bodyColors = {
      fill: "#FFFFFF",
    };
    const capColors = {
      fill: "#FFFFFF",
    };

    return (
      <Fragment>
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            575: {
              slidesPerView: 1,
            },
            821: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1400: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
          {data?.items?.map((items) => (
            <SwiperSlide key={items._id}>
              <svg
                onClick={() => handleSleeveId(items._id)}
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 650 1010"
                width="100%"
                height="auto"
              >
                {svgData?.svg.elements?.map((item) => (
                  <g data-name="Layer 1">
                    {item.id == "left-sleeve" && (
                      <g id="left-sleeve">
                        <path
                          style={sleeveColors}
                          className="mainPattern"
                          d={item.d}
                        />
                      </g>
                    )}
                    {item.id == "right-sleeve" && (
                      <g id="right-sleeve">
                        <path
                          style={sleeveColors}
                          className="mainPattern"
                          d={item.d}
                        />
                      </g>
                    )}

                    {item.id == "body" && (
                      <g id="body">
                        <path
                          style={bodyColors}
                          className="mainPattern"
                          d={item.d}
                        />
                      </g>
                    )}
                    {item.id == "body-highlight" && (
                      <g id="body-highlight">
                        <path
                          style={bodyColors}
                          className="mainPattern"
                          d={item.d}
                        />
                      </g>
                    )}

                    {
                      <g id="cap">
                        {item.id == "cap-front" && (
                          <path
                            style={capColors}
                            className="mainPattern"
                            d={item.d}
                          />
                        )}

                        {item.id == "cap-bill" && (
                          <path
                            style={capColors}
                            className="mainPattern"
                            d={item.d}
                          />
                        )}
                        {item.id == "facecut" && (
                          <path className="mainPattern" d={item.d} />
                        )}
                      </g>
                    }
                  </g>
                ))}
                <svg
                  className="paapttern"
                  style={{ strokeWidth: "3" }}
                  dangerouslySetInnerHTML={{
                    __html: `${items.SvgPath?.replace(/^\"|\"$/g, "")}>`,
                  }}
                />
                {svgData?.svg.elements?.map((item, i) => (
                  <g data-name="Layer 1" key={i}>
                    {item.id === "outlinee" && (
                      <g id="outlinee">
                        {item.pattern.map((itemss, i) => (
                          <path
                            d={itemss.d}
                            style={{ fill: "#e6e7e8", opacity: "0.4" }}
                          />
                        ))}
                      </g>
                    )}
                  </g>
                ))}
                <g id="eeellleSleeve">
                  {svgData?.svg.elements?.map((item) => (
                    <g>
                      {item.id == "watermark" && (
                        <g id="watermark">
                          <path
                            style={waterMark}
                            d={item.d}
                            id="eeellleSleeve"
                          />
                        </g>
                      )}

                      {item.id == "watermark-1" && (
                        <g id="watermark-1">
                          <path
                            style={waterMark}
                            d={item.d}
                            id="eeellleSleeve"
                          />
                        </g>
                      )}

                      {item.id == "watermark-2" && (
                        <g id="watermark-2">
                          <path
                            style={waterMark}
                            d={item.d}
                            id="eeellleSleeve"
                          />
                        </g>
                      )}

                      {item.id == "watermark-3" && (
                        <g id="watermark-3">
                          <path
                            style={waterMark}
                            d={item.d}
                            id="eeellleSleeve"
                          />
                        </g>
                      )}

                      {item.id == "watermark-4" && (
                        <g id="watermark-4">
                          <path
                            style={waterMark}
                            d={item.d}
                            id="eeellleSleeve"
                          />
                        </g>
                      )}

                      {item.id == "watermark-5" && (
                        <g id="watermark-5">
                          <path
                            style={waterMark}
                            d={item.d}
                            id="eeellleSleeve"
                          />
                        </g>
                      )}

                      {item.id == "watermark-6" && (
                        <g id="watermark-6">
                          <path
                            style={waterMark}
                            d={item.d}
                            id="eeellleSleeve"
                          />
                        </g>
                      )}

                      {item.id == "watermark-7" && (
                        <g id="watermark-7">
                          <path
                            style={waterMark}
                            d={item.d}
                            id="eeellleSleeve"
                          />
                        </g>
                      )}

                      {item.id == "watermark-8" && (
                        <g id="watermark-8">
                          <path
                            style={waterMark}
                            d={item.d}
                            id="eeellleSleeve"
                          />
                        </g>
                      )}
                    </g>
                  ))}
                </g>
              </svg>
              <p className="patternName">{items.Name}</p>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="ceeenter">
          <Button className="restart" onClick={() => handleSleeveId("")}>
            <img src={restartImage} alt="asfd" /> Reset Pattern
          </Button>
        </div>
      </Fragment>
    );
};

export default SleeveMarkingSlider;