import React, { Fragment, useEffect, useState } from "react";
import LoginPage from "../Components/Loginn/LoginPage";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import logo from "../Assets/logoSaddleBattle.png";

function Login() {
  const navigate = useNavigate();

  const [token, setToken] = useState();
  const tokens = Cookies.get("token");

  useEffect(() => {
    if (tokens) {
      navigate("/dashboard");
    }
  }, [token]);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row loginBox loginForm">
          <div className="col-sm-5">
            <div className="mainLogo">
              <img src={logo} alt="Logo" />
            </div>
            <LoginPage />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
