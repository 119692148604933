import { CChart } from '@coreui/react-chartjs'
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboard } from "../../Redux/Action/dashboard";
import moment from "moment";
import { Spin } from 'antd';
import Loader from "../Loader/Loader";

const Charts = () => {
  const dispatch = useDispatch();
  const { data, startDate, endDate, loading } = useSelector(
    (state) => state.dashboard
  );

  useEffect(() => {
    dispatch(dashboard());
  }, [dispatch]);

  const monthNames = data?.data?.map((date) => {
    if (date.month) {
      return moment(date.month, "YYYY-MM").format("MMMM");
    } else {
      return date.date;
    }
  });

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(dashboard({ startDate, endDate }));
    }
  }, [dispatch, startDate, endDate]);

  const generateDateRange = (start, end) => {
    const dateArray = [];
    let currentDate = moment(start);
    const stopDate = moment(end);

    while (currentDate <= stopDate) {
      dateArray.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "days");
    }
    return dateArray;
  };

  const dateRange =
    startDate && endDate ? generateDateRange(startDate, endDate) : [];

  const dateCounts = dateRange.map((date) => {
    const existingData = data?.data?.find((item) => item.date === date);
    return existingData ? existingData.count : 0;
  });
  const dataCount = data?.data?.map((item) => item.count);
  useEffect(() => {
    dispatch(dashboard({}));
  }, [dispatch]);
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 barchart">
            {loading ? (
              <div className="lottie-dash">
                <Loader />
              </div>
            ) : (
              <CChart
                type="bar"
                data={{
                  labels: startDate && endDate ? dateRange : monthNames,
                  datasets: [
                    {
                      label: "Downloads",
                      backgroundColor: "#121945",
                      data: startDate && endDate ? dateCounts : dataCount,
                    },
                  ],
                }}
                labels="days"
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        color: "#000",
                      },
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        color: "#ffffff1a",
                      },
                      ticks: {
                        color: "#000",
                      },
                    },
                    y: {
                      grid: {
                        color: "#ffffff1a",
                      },
                      ticks: {
                        color: "#000",
                      },
                    },
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Charts
