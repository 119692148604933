import { combineReducers } from "@reduxjs/toolkit";
import getBodyPattern from "./Reducer/getBodyPatternReducer";
import getSleevePattern from "./Reducer/getSleevePatternReducer";
import getCapPattern from "./Reducer/getCapPatternReducer";
import postCustomerInfo from "./Reducer/postCustomerInfoReducer";
import getPayementIntent from "./Reducer/getPayementIntentReducer";
import postBuyShirt from "./Reducer/postBuyShirtReducer";
import authAdmin from "./Reducer/authAdminReducer";
import color from "./Reducer/colorReducer";
import pattern from "./Reducer/patternReducer";
import dashboard from "./Reducer/dashboardReducer";
import forgetPassword from "./Reducer/forgetPasswordReducer";
import shirtType from "./Reducer/shirtTypeReducer";

const rootReducer = combineReducers({
  getBodyPattern: getBodyPattern,
  getSleevePattern: getSleevePattern,
  getCapPattern: getCapPattern,
  getPayementIntent: getPayementIntent,
  postCustomerInfo: postCustomerInfo,
  postBuyShirt: postBuyShirt,
  color: color,
  pattern: pattern,
  authAdmin: authAdmin,
  dashboard: dashboard,
  forgetPassword: forgetPassword,
  shirtType: shirtType,
});
export default rootReducer