import React, { Fragment } from 'react'
import {Tabs} from 'antd'
import CapMarkingSlider from '../Slider/CapMarkingSlider';
import ListColor from '../../ColorCompTab/ListColor';

const { TabPane } = Tabs;

const CapTab = ({
  handleCapColor,
  handleCapMarkingColor,
  handleCapId,
  capColors,
  capMarkingColor,
}) => {
  return (
    <Fragment>
      <div className="row boddyTab">
        <div className="col-sm-12">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Cap Colour" key="1">
              <ListColor handleColor={handleCapColor} colorsss={capColors}/>
            </TabPane>
            <TabPane tab="Cap Marking" key="2">
              <CapMarkingSlider handleCapId={handleCapId} />
            </TabPane>
            <TabPane tab="Cap Marking Colour" key="3">
              <ListColor handleColor={handleCapMarkingColor} colorsss={capMarkingColor}/>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Fragment>
  );
};

export default CapTab