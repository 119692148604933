import React, { Fragment } from 'react'
import Sidebar from '../Components/Sidebar'
import HeaderDesignCreationPurpleJacket from '../Components/DesignCreationPurpleJacket/HeaderDesignCreationPurpleJacket'
import ShirtThreeSvg from '../Components/DesignCreationPurpleJacket/ShirtThreeSvg'
// import Shirts from '../Components/DesignCreationWhiteJackets/Shirts'
// import HeaderDesignCreationPlainJacket from '../Components/DesignCreationPlainJackets/HeaderDesignCreationPlainJacket'

function DesignCreationPurpleJacket() {
    return (
        <Fragment>
            <div class="container-fluid">
                <div className='row'>
                    <div className='col-sm-3 sidebarss'>
                        <Sidebar />
                    </div>
                    <div className='col-sm-9 ssidebar'>
                        {/* <HeaderDesignCreationPlainJacket />
                        <Shirts /> */}
                        <HeaderDesignCreationPurpleJacket />
                        {/* <Shirtt */}
                        <ShirtThreeSvg />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DesignCreationPurpleJacket