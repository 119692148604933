import { Button, Form, Input, message } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ChangePasswordModal from "./ChangePasswordModal";
import { useDispatch, useSelector } from "react-redux";
import { getMyDetails, updateProfile } from "../../Redux/Action/authAdmin";
import { clearState } from "../../Redux/Reducer/authAdminReducer";

const ProfileFields = () => {
  const { data, editSuccess, editError } = useSelector(
    (state) => state.authAdmin
  );
  const [form] = Form.useForm();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onFinish = (values) => {
    dispatch(updateProfile(values));
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Failed");
  };

  useEffect(() => {
    dispatch(getMyDetails());
  }, [dispatch]);

  useEffect(() => {
    if (editError) {
      message.error(editError);
      dispatch(clearState());
    }
    if (editSuccess) {
      message.success(editSuccess);
      dispatch(clearState());
    }
  }, [editSuccess, editError, dispatch]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        FirstName: data.FirstName,
        LastName: data.LastName,
        Email: data.Email,
      });
    }
  }, [data]);

  return (
    <Fragment>
      <div className="container-fluid profile">
        <Form
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          initialValues={{
            FirstName: data?.FirstName,
            LastName: data?.LastName,
            Email: data?.Email,
          }}
        >
          <div className="row">
            <div className="col-sm-12 fortop">
              <Button type="primary" onClick={handleShow}>
                Change Password
              </Button>
            </div>

            <div className="col-sm-12">
              <Form.Item
                name="FirstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </div>

            <div className="col-sm-12">
              <Form.Item
                name="LastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                  },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </div>

            <div className="col-sm-12">
              <Form.Item
                name="Email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                  {
                    type: "email",
                    message: "Please input a valid email address!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>

            <div className="col-sm-12 fortop submit">
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </div>
        </Form>
      </div>

      <Modal onHide={handleClose} show={show} className="AddModal">
        <ChangePasswordModal handleClose={handleClose} />
      </Modal>
    </Fragment>
  );
};

export default ProfileFields;
