export const loginData = {
    username: 'testuser',
    email: 'admin@ownersilk.com',
    password: 'abcd1234'
} 

export const getShirts = [
  {
    id: "87ce0e0d-46f5-44c5-9d9f-9db2a787c3fe",
    name: "Shirt Pattern 01",
    source:
      "https://saddlebattleownersilk.s3.amazonaws.com/ShirtType/a28be651051ba33a949262f280be3cd30014ac589306931e42ce1ff3c00983e5",
    navigation: "blue-jacket",
  },
  {
    id: "73a326fd-ef6b-454c-bd18-454679722e84",
    name: "Shirt Pattern 02",
    source:
      "https://saddlebattleownersilk.s3.amazonaws.com/ShirtType/adf63a26ff9f6788ed8997b930d50b99b2d42c419360eb4d51e103c996648f35",
    navigation: "purple-jacket",
  },
  {
    id: "2fe98f98-a8ea-4929-a2ec-03e9cf8ad2c4",
    name: "Shirt Pattern 03",
    source:
      "https://saddlebattleownersilk.s3.amazonaws.com/ShirtType/39d12a5a0bb1280ee6c8bc5bc31a671cae7646f7859456ae4a4ec5e60db43ffe",
    navigation: "white-jacket",
  },
  {
    id: "2164e36e-f863-4e4d-be2a-22950b38dee1",
    name: "Shirt Pattern 04",
    source:
      "https://saddlebattleownersilk.s3.amazonaws.com/ShirtType/86d8652bb3315bcf325830247429dd6e846cb185ade81d3adae8c0891526a3e0",
    navigation: "plain-jacket",
  },
];

export const shirtPatterns = [
    {
        patternId: 'abcd-saasacsd-12122-sasas',
        shirtId: '2164e36e-f863-4e4d-be2a-22950b38dee1',
        patternName: 'Check',
        patterntType: 'Body',
        patternStatus: 'active',
        patternSource: './ShirtSvgs/Shirt1Body1P1.svg'
    },
    {
        patternId: 'abcd-saasacsd-12122-sasas',
        shirtId: '2164e36e-f863-4e4d-be2a-22950b38dee1',
        patternName: 'Braces',
        patterntType: 'Body',
        patternStatus: 'active',
        patternSource: './ShirtSvgs/Shirt1Body1P2.svg'
    },
    {
        patternId: 'abcd-saasacsd-12122-sasas',
        shirtId: '2164e36e-f863-4e4d-be2a-22950b38dee1',
        patternName: 'Check Sleeve',
        patterntType: 'Sleeeve',
        patternStatus: 'active',
        patternSource: './ShirtSvgs/Shirt1Sleeve1P1.svg'
    },
    {
        patternId: 'abcd-saasacsd-12122-sasas',
        shirtId: '2164e36e-f863-4e4d-be2a-22950b38dee1',
        patternName: 'Braces Sleeve',
        patterntType: 'Sleeeve',
        patternStatus: 'active',
        patternSource: './ShirtSvgs/Shirt1Sleeve1P2.svg'
    },
    {
        patternId: 'abcd-saasacsd-12122-sasas',
        shirtId: '2164e36e-f863-4e4d-be2a-22950b38dee1',
        patternName: 'Check',
        patterntType: 'Cap',
        patternStatus: 'active',
        patternSource: './ShirtSvgs/Shirt1Cap1P1.svg'
    }
]

